import { SET_SIXMONTHTRIP } from '../actions/types'

const initialState = null
const sixMonthAllTripReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_SIXMONTHTRIP:
      return payload
    default:
      return state
  }
}

export default sixMonthAllTripReducer
