import React from 'react'

const AttributeList = React.lazy(() => import('../pages/Attributes/AttributeList/AttributeList'))
const AttributeDetail = React.lazy(() =>
  import('../pages/Attributes/AttributeDetail/AttributeDetail'),
)
const CreateAttribute = React.lazy(() =>
  import('../pages/Attributes/CreateAttribute/CreateAttribute'),
)
const EditAttribute = React.lazy(() => import('../pages/Attributes/EditAttribute/EditAttribute'))

export { AttributeList, AttributeDetail, CreateAttribute, EditAttribute }
