import { SET_TRIPPRODUCTBONUS } from 'src/actions/types'

const initialState = null
const tripProductGroupReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_TRIPPRODUCTBONUS:
      return payload
    default:
      return state
  }
}

export default tripProductGroupReducer
