const invoiceThemes = {
  invoiceThemeList: '/invoice-themes/list',
  invoiceThemeDetail: '/invoice-themes/detail',
  createInvoiceTheme: '/invoice-themes/create',
  editInvoiceTheme: '/invoice-themes/update',
  deleteInvoiceTheme: '/invoice-themes/delete',
  uploadThemeImage: '/invoice-themes/upload-image',
  deleteThemeImage: '/invoice-themes/delete-image',
}

export default invoiceThemes
