import { TOGGLE_BUTTONDISABLED } from 'src/actions/types'

const buttonDisableReducer = (state = false, action) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_BUTTONDISABLED:
      return payload
    default:
      return state
  }
}

export default buttonDisableReducer
