import React from 'react'

const CreatePromotionGroup = React.lazy(() =>
  import('../pages/PromotionGroups/CreatePromotionGroups/CreatePromotionGroup'),
)

const EditPromotionGroup = React.lazy(() =>
  import('../pages/PromotionGroups/EditPromotionGroups/EditPromotionGroup'),
)

const PromotionGroupList = React.lazy(() =>
  import('../pages/PromotionGroups/PromotionGroupList/PromotionGroupList'),
)

const PromotionGroupDetail = React.lazy(() =>
  import('../pages/PromotionGroups/PromotionGroupDetail/PromotionGroupDetail'),
)

export { CreatePromotionGroup, PromotionGroupList, PromotionGroupDetail, EditPromotionGroup }
