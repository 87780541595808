import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CCard, CCardBody } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabPanel from '../../../../components/Material/TabPanel'

import Breadcrumb from './Breadcrumb'

const GiftList = () => {
  const { id } = useParams()
  let navigate = useNavigate()

  const [activeKey, setActiveKey] = useState(3)

  const handleChange = (event, newValue) => {
    setActiveKey(newValue)
    if (newValue === 0) {
      navigate(`/agents/${id}`)
    }
    if (newValue === 1) {
      navigate(`/agents/${id}/products`)
    }
    if (newValue === 2) {
      navigate(`/agents/${id}/regions`)
    }
    if (newValue === 3) {
      navigate(`/agents/${id}/gifts`)
    }
    if (newValue === 4) {
      navigate(`/agents/${id}/posts`)
    }
  }
  return (
    <>
      <Breadcrumb />
      <CCard>
        <CCardBody>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="product_table">
            <Tabs value={activeKey} onChange={handleChange} aria-label="Agent Product List">
              <Tab label="Detail" />
              <Tab label="Products" />
              <Tab label="Sale Region" />
              <Tab label="Posts" />
              {/* <Tab label="Gifts" /> */}
            </Tabs>
          </Box>
          <TabPanel value={activeKey} index={4}>
            <>Gift List</>
          </TabPanel>
        </CCardBody>
      </CCard>
    </>
  )
}

export default GiftList
