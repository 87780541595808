import { SET_QUESTIONTYPE } from 'src/actions/types'

const initialState = []
const questionTypeReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_QUESTIONTYPE:
      return payload
    default:
      return state
  }
}

export default questionTypeReducer
