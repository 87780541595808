import React from 'react'

const MonthlyPurchaseReportList = React.lazy(() =>
  import('../pages/Reports/Monthly/AgentReportList'),
)
const MonthlyPurchaseReportDetail = React.lazy(() =>
  import('../pages/Reports/Monthly/MonthlyReportProductList'),
)

const MonthlyPurchaseReportAgentList = React.lazy(() =>
  import('../pages/Reports/Monthly/MonthlyReportChildAgentList'),
)

const MonthlyPurchaseReportDirectSaleList = React.lazy(() =>
  import('../pages/Reports/Monthly/MonthlyReportDirectSaleList'),
)

const MonthlyPurchaseReportEndUserList = React.lazy(() =>
  import('../pages/Reports/Monthly/MonthlyReportEndUserList'),
)

const MonthlyPurchaseReportEndUserDetail = React.lazy(() =>
  import('../pages/Reports/Monthly/MonthlyReportEndUserDetail'),
)

const YearlyPurchaseReportList = React.lazy(() => import('../pages/Reports/Yearly/AgentReportList'))
export {
  MonthlyPurchaseReportList,
  MonthlyPurchaseReportDetail,
  MonthlyPurchaseReportAgentList,
  MonthlyPurchaseReportDirectSaleList,
  MonthlyPurchaseReportEndUserList,
  YearlyPurchaseReportList,
  MonthlyPurchaseReportEndUserDetail,
}
