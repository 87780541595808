const yearEnd = {
  yearEndAwardList: '/admin/year-end-awards/list',
  yearEndAwardDetail: '/admin/year-end-awards/detail',
  yearEndAwardProductGroup: '/admin/year-end-awards/groups/list',
  downloadYearEndAwards: '/admin/year-end-awards/download-excel',
  downloadYearEndProductGroup: '/admin/year-end-awards/groups/download-excel',
  yearEndPrizes: '/admin/year-end-prizes/all',
  yearEndRuleList: '/admin/year-end-rules/list',
  createYearEndRule: '/admin/year-end-rules/create',
  yearEndRuleDetail: '/admin/year-end-rules/detail',
  updateYearEndRule: '/admin/year-end-rules/update',
  deleteYearEndRule: '/admin/year-end-rules/delete',
  yearEndReportList: '/admin/year-end-reports/list',
  yearEndReportDetail: '/admin/year-end-reports/detail',
  downloadYearEndReport: '/admin/year-end-reports/download-excel',
  yearEndReportGroup: '/admin/year-end-reports/details/list',
  downloadYearEndReportGroup: '/admin/year-end-reports/details/download-excel',
  agentYearEndReportDetail: '/agents/year-end-reports/detail/monthly-list',
  yearEndTripGroupList: '/admin/year-end-trips/groups/list',
  downloadYearEndTripGroupReport: '/admin/year-end-trips/groups/download-excel',
  uploadYearEndTripGroup: '/admin/year-end-trips/groups/upload-image',
  downloadYearEndTripGroup: '/admin/year-end-trips/groups/download-image',
  uploadYearEndAwardGroup: '/admin/year-end-awards/groups/upload-image',
  downloadYearEndAwardGroup: '/admin/year-end-awards/groups/download-image',
  yearEndBonusAwardList: '/admin/year-end-bonus-awards/list',
  yearEndBonusAwardDetail: '/admin/year-end-bonus-awards/detail',
  yearEndBonusAwardProductGroup: '/admin/year-end-bonus-awards/groups/list',
  downloadYearEndBonusAward: '/admin/year-end-bonus-awards/download-excel',
  downloadYearEndBonusProductGroup: '/admin/year-end-bonus-awards/groups/download-excel',
  yearEndBonusAwardChildList: '/agents/year-end-bonus-awards/childs/list',
}

export default yearEnd
