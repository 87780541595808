import React from 'react'
import Lottie from 'lottie-react'
import loading from '../assets/animation/table-loading.json'
import PropTypes from 'prop-types'

import { CTableRow, CTableBody, CTableDataCell } from '@coreui/react'

const TableLoading = ({ colspan }) => {
  return (
    <CTableBody>
      <CTableRow>
        <CTableDataCell colSpan={colspan}>
          <div className="d-flex justify-content-center">
            {/* <img src="/images/spinner.svg" alt="spinner" /> */}
            <div style={{ width: 70 }}>
              <Lottie animationData={loading} loop={true} />
            </div>
          </div>
        </CTableDataCell>
      </CTableRow>
    </CTableBody>
  )
}

TableLoading.propTypes = {
  colspan: PropTypes.number.isRequired,
}

export default TableLoading
