const bonusrule = {
  ruleList: '/bonus-rules/list',
  ruleDetail: '/bonus-rules/detail',
  createRule: '/bonus-rules/create',
  updateRule: '/bonus-rules/update',
  deleteRule: '/bonus-rules/delete',
  triBonusRuleList: '/admin/tri-bonus-rules/list',
  triBonusRuleDetail: '/admin/tri-bonus-rules/detail',
  createTriBonusRule: '/admin/tri-bonus-rules/create',
  updateTriBonusRule: '/admin/tri-bonus-rules/update',
  deleteTriBonusRule: '/admin/tri-bonus-rules/delete',
  allPrizeList: '/admin/tri-bonus-prizes/all',
}

export default bonusrule
