import {
  ADD_NOTI_EXCLUDE_AGENT,
  REMOVE_NOTI_EXCLUDE_AGENT,
  ADD_NOTI_EXCLUDE_ALL_AGENTS,
  REMOVE_NOTI_EXCLUDE_ALL_AGENTS,
} from '../actions/types'

const initialState = []
const notiExcludeAgentReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_NOTI_EXCLUDE_AGENT: {
      const existAgent = state.findIndex((element) => element.id === payload?.agent?.id)
      if (existAgent < 0) {
        return [...state, payload.agent]
      }
      return [...state]
    }
    case REMOVE_NOTI_EXCLUDE_AGENT:
      return state.filter((element) => element?.id !== payload?.agent?.id)
    case ADD_NOTI_EXCLUDE_ALL_AGENTS:
      return payload.agents
    case REMOVE_NOTI_EXCLUDE_ALL_AGENTS:
      return []
    default:
      return state
  }
}

export default notiExcludeAgentReducer
