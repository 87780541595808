const products = {
  activeProducts: '/products/list',
  inactiveProducts: '/products/inactive-list',
  productDetail: '/products/detail',
  adminProductDetail: '/admin/products/detail',
  createProduct: '/admin/products/create',
  updateProduct: '/products/update',
  updateProductStatus: '/admin/products/update-status',
  deleteProduct: '/products/delete',
  activeProduct: '/products/active',
  allProducts: '/products/all',
  uploadThumbnail: '/products/upload-thumbnails',
  deleteThumbnailImage: '/admin/products/upload-thumbnails/delete',
  uploadProductImages: '/admin/products/upload-images',
  deleteProductImage: '/products/images/delete',
  productPrices: '/products/price/list',
  priceList: '/prices/list',
  priceDetail: '/prices/detail',
  priceUpdate: '/prices/update',
  createProductSalePromotion: '/products/promotions/sale-promotions/create',
  createProductMonthlyPromotion: '/products/promotions/monthly-promotions/create',
  createProductFOCPromotion: '/products/promotions/foc-promotions/create',
  createProductDiscount: '/products/promotions/discount-promotions/create',
  deleteFlyer: '/admin/products/flyers/delete',
  uploadFlyer: '/products/upload-flyer',
  downloadFlyers: '/products/flyers/download-image',
  catalogueList: '/products/catalogues/list',
  createCatalogue: '/products/catalogues/create',
  catalogueDetail: '/products/catalogues/detail',
  editCatalogue: '/products/catalogues/update',
  deleteCatalogue: '/products/catalogues/delete',
}

export default products
