import { SET_REGION } from '../actions/types'

const intialState = []

const regionReducer = (state = intialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_REGION:
      return payload
    default:
      return state
  }
}

export default regionReducer
