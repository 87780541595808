import { CTableDataCell, CTableRow } from '@coreui/react'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Modal from 'src/components/Modal'
import FallbackImage from 'src/components/FallbackImage'
import UserPermission from 'src/components/Common/UserPermission'

export const SixMonthFrameTableRow = (props) => {
  const { paginateIndex, index, bonusFrame, handleBonusFrame, openModal } = props
  const [visible, setVisible] = useState(false)

  const clickHandler = () => {
    handleBonusFrame(bonusFrame?.id)
  }
  return (
    <CTableRow>
      <CTableDataCell className="text-center">{paginateIndex + index}</CTableDataCell>
      <CTableDataCell className="text-center">
        {bonusFrame?.path && (
          <div className="image_path">
            <FallbackImage
              path={bonusFrame?.path}
              title="frame-img"
              openModal={() => openModal(bonusFrame?.path)}
            />
            {/* <img src={bonusFrame?.path} alt="six-month-frame" loading="lazy" /> */}
          </div>
        )}
      </CTableDataCell>
      <CTableDataCell className="text-center">
        {getFrameType(bonusFrame?.all_frame_type)}
      </CTableDataCell>
      <CTableDataCell className="text-center">{bonusFrame?.bonus_type}</CTableDataCell>
      <CTableDataCell className="text-center">{bonusFrame?.region_name}</CTableDataCell>
      <CTableDataCell className="text-center">
        <Link to={`/bonus-frames/${bonusFrame.id}?bonus_type=six-months`}>
          <span title="Detail Bonus Frame" className="mx-2">
            <FontAwesomeIcon icon={faEye} color="rgb(0, 200, 83)" />
          </span>
        </Link>
        <UserPermission>
          <Link to={`/bonus-frames/${bonusFrame.id}/edit?bonus_type=six-months`}>
            <span title="Edit Bonus Frame" className="mx-2">
              <FontAwesomeIcon icon={faEdit} color="rgb(33, 150, 243)" />
            </span>
          </Link>
        </UserPermission>
        <UserPermission>
          <span
            title="Delete Bonus Frame"
            className="mx-2"
            style={{ cursor: 'pointer' }}
            onClick={() => setVisible(!visible)}
          >
            <FontAwesomeIcon icon={faTrash} color="#FF4747" />
          </span>
        </UserPermission>
      </CTableDataCell>
      <Modal
        visible={visible}
        setVisible={setVisible}
        title="Delete Bonus Frame"
        body={`Are you sure you want to delete ${bonusFrame.name}?`}
        clickHandler={clickHandler}
      />
    </CTableRow>
  )
}

const getFrameType = (allFrameType) => {
  return allFrameType
    ? allFrameType > 1
      ? `${allFrameType} Product Frames`
      : `${allFrameType} Product Frame`
    : '-'
}

SixMonthFrameTableRow.propTypes = {
  paginateIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  bonusFrame: PropTypes.object.isRequired,
  handleBonusFrame: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
}
