import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CCol, CForm, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import RegionDropDown from 'src/components/SaleRegions/RegionDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBonusTypes } from 'src/actions/bonusType'
import { BonusTypeEnum } from 'src/configs/EnumData'

export const SixMonthFrameFilter = ({ payload, setPayload }) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const bonusTypeList = useSelector((state) => state.bonusTypes)
  const handleChange = (event) => {
    const { name, value } = event.target
    setPayload((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  useEffect(() => {
    if (bonusTypeList?.length > 0) {
      return () => {}
    }

    const fetchTypes = async () => {
      dispatch(fetchBonusTypes(token)).catch((error) => {
        const response = error?.response?.data
        alert(response)
      })
    }

    fetchTypes()
  }, [])

  return (
    <CForm>
      <CRow>
        <CCol xs={12} md={4}>
          <CFormLabel htmlFor="bonus_type">Bonus Type</CFormLabel>
          <CFormSelect
            id="bonus_type"
            name="bonus_type"
            value={payload?.bonus_type}
            onChange={handleChange}
          >
            <option value="6 months">All</option>
            {bonusTypeList?.map((type, index) =>
              type?.id === BonusTypeEnum.SixMonthCertificate ||
              type?.id === BonusTypeEnum.SixMonthTrip ? (
                <option key={type?.id} value={type?.name}>
                  {type?.name}
                </option>
              ) : null,
            )}
          </CFormSelect>
        </CCol>
        <RegionDropDown payload={payload} setPayload={setPayload} />
      </CRow>
    </CForm>
  )
}

SixMonthFrameFilter.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
}
