import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CCol, CFormLabel, CFormSelect } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductGroups } from 'src/actions/productGroup'

const ProductGroupDropDown = ({
  payload,
  setPayload,
  regionFilter = false,
  payloadKey = 'group_id',
  disabled = false,
  col = 12,
}) => {
  const dispatch = useDispatch()
  const groups = useSelector((state) => state.productGroups)
  const auth = useSelector((state) => state.auth.token)

  useEffect(() => {
    const params =
      regionFilter && 'sale_region' in payload
        ? {
            sale_region: payload?.sale_region,
            bonus_type_id: payload?.bonus_type_id,
          }
        : { bonus_type_id: payload?.bonus_type_id }
    dispatch(fetchProductGroups(auth, params)).catch((error) => {
      alert(error)
    })
  }, [payload?.bonus_type_id, payload?.sale_region])

  return (
    <CCol xs={12} md={col}>
      <div className="mb-3">
        <CFormLabel htmlFor={payloadKey}>Product Group</CFormLabel>
        <CFormSelect
          aria-label="All"
          value={payload[payloadKey] ?? ''}
          onChange={(e) =>
            setPayload({
              ...payload,
              [payloadKey]: e.target.value,
              monthly_group_id: e.target.value,
            })
          }
          disabled={disabled}
        >
          <option value="">All</option>
          {groups?.map((group, index) => (
            <option key={index} value={group.id}>
              {group.name} {group?.sale_region && `(${group?.sale_region})`}
            </option>
          ))}
        </CFormSelect>
      </div>
    </CCol>
  )
}

ProductGroupDropDown.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  regionFilter: PropTypes.bool,
  payloadKey: PropTypes.string,
  disabled: PropTypes.bool,
  col: PropTypes.string,
}
export default ProductGroupDropDown
