import React from 'react'

const BonusGroupList = React.lazy(() =>
  import('../pages/BonusGroups/BonusGroupList/BonusGroupList'),
)

const BonusGroupDetail = React.lazy(() =>
  import('../pages/BonusGroups/BonusGroupDetail/BonusGroupDetail'),
)

const CreateBonusGroup = React.lazy(() =>
  import('../pages/BonusGroups/CreateBonusGroup/CreateBonusGroup'),
)

const EditBonusGroup = React.lazy(() =>
  import('../pages/BonusGroups/EditBonusGroup/EditBonusGroup'),
)

export { BonusGroupList, BonusGroupDetail, CreateBonusGroup, EditBonusGroup }
