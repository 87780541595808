const exports = {
  saleOverallExcel: '/agents/sale_overall_report/excel',
  saleDetailExcel: '/agents/sale_detail_report/excel',
  purchaseOverallExcel: '/agents/purchase_overall_report/excel',
  purchaseDetailExcel: '/agents/purchase_detail_report/excel',
  saleOverallPdf: '/agents/sale_overall_report/pdf',
  saleDetailPdf: '/agents/sale_detail_report/pdf',
  purchaseOverallPdf: '/agents/purchase_overall_report/pdf',
  purchaseDetailPdf: '/agents/purchase_detail_report/pdf',
  allBonusPurchaseExcel: 'agents/all/bonus-purchases/excel',
}

export default exports
