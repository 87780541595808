const gifts = {
  gifts: '/gifts/list',
  giftDetail: '/gifts/detail',
  createGift: '/gifts/create',
  updateGift: '/gifts/update',
  deleteGift: '/gifts/delete',
  uploadGiftImage: '/gifts/upload-images',
  deleteGiftImage: '/gifts/images/delete',
  endUserGifts: '/end-user-gifts/list',
  endUserGiftDetail: '/end-user-gifts/detail',
  createEndUserGift: '/end-user-gifts/create',
  editEndUserGift: '/end-user-gifts/update',
  deleteEndUserGift: '/end-user-gifts/delete',
}

export default gifts
