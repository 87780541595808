import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CFormInput,
} from '@coreui/react'
import AgentCode from '../../edit/AgentCode'
import API from 'src/network/API'
import useAxios from 'src/hooks/useAxios'
import ProfileUpload from 'src/components/Uploads/ProfileUpload/ProfileUpload'

const ProductProfieUpload = ({
  agentId,
  productId,
  showUpload,
  setShowUpload,
  productName,
  selectedImage,
  setSelectedImage,
  handleRefresh,
}) => {
  const [errors, setErrors] = useState('')
  const { instance } = useAxios()
  const [loading, setLoading] = useState(false)

  const uploadImage = async (image) => {
    setLoading(true)
    let fd = new FormData()
    fd.append('agent_id', agentId)
    fd.append('product_id', productId)
    fd.append('image', image)

    const url = `${API.uploadProductProfile}`

    const response = await instance.post(url, fd).catch((error) => {
      const errors = error.response.data
      const { code, message } = errors
      if (code === 400) {
        setErrors(message)
        setLoading(false)
      }
    })

    if (response) {
      const {
        data: { path },
      } = response.data

      setSelectedImage(path)
      toast.success('Profile Picture Uploaded Successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
      setLoading(false)
      handleRefresh()
    }
  }

  const handleChangeImage = (image) => {
    uploadImage(image)
  }
  return (
    <>
      <CModal
        visible={showUpload}
        onClose={() => setShowUpload(false)}
        aria-labelledby="importModal"
        aria-describedby="modalDescription"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle>Update Product Profile</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <ProfileUpload
            selectedImage={selectedImage ? selectedImage : '/images/fallback.png'}
            handleChangeImage={handleChangeImage}
            showEdit={true}
            loading={loading}
          />
          <AgentCode agentId={agentId} />
          {/* <div className="d-flex justify-content-center">
            <b>Product : {productName}</b>
          </div> */}
          <CFormInput value={`Product : ${productName}`} className="mb-3" readOnly />
        </CModalBody>
      </CModal>
    </>
  )
}

ProductProfieUpload.propTypes = {
  showUpload: PropTypes.bool.isRequired,
  setShowUpload: PropTypes.func.isRequired,
  agentId: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  selectedImage: PropTypes.string.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
}

export default ProductProfieUpload
