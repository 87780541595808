import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Box, Tab, Tabs } from '@mui/material'

const BONUSFRAME_TABS = {
  0: '/monthly/bonus-frames',
  1: '/three-months/bonus-frames',
  2: '/six-months/bonus-frames',
  3: '/year-end/bonus-frames',
}
const BonusFrameTabs = ({ activeKey, onTabChange }) => {
  let navigate = useNavigate()

  const handleChange = (event, newValue) => {
    onTabChange(newValue)
    navigate(BONUSFRAME_TABS[newValue])
  }
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="transaction_table">
      <Tabs value={activeKey} onChange={handleChange} aria-label="Bonus Frame Tabs">
        <Tab label="Monthly" />
        <Tab label="Three Months" />
        <Tab label="Six Months" />
        <Tab label="Year End" />
      </Tabs>
    </Box>
  )
}

BonusFrameTabs.propTypes = {
  activeKey: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
}
export default BonusFrameTabs
