import React from 'react'

const AgentLevelAgentList = React.lazy(() => import('../pages/AgentLevels/AgentList/AgentList'))

const AgentLevelList = React.lazy(() => import('../pages/AgentLevels/List/AgentLevelList'))

const CreateAgentLevel = React.lazy(() =>
  import('../pages/AgentLevels/CreateAgentLevel/CreateAgentLevel'),
)
const EditAgentLevel = React.lazy(() =>
  import('../pages/AgentLevels/EditAgentLevels/EditAgentLevel'),
)

const AgentLevelDetail = React.lazy(() =>
  import('../pages/AgentLevels/AgentLevelDetail/AgentLevelDetail'),
)

export { AgentLevelAgentList, AgentLevelList, CreateAgentLevel, EditAgentLevel, AgentLevelDetail }
