import React from 'react'

const AllBonusAwardList = React.lazy(() => import('../pages/BonusAwards/AllFrame/BonusAwardList'))
const AllBonusAwardDetail = React.lazy(() =>
  import('../pages/BonusAwards/AllFrame/BonusAwardDetail/BonusAwardDetail'),
)
const ProductBonusAwardList = React.lazy(() =>
  import('../pages/BonusAwards/ProductFrame/ProductBonusAwardList'),
)

export { AllBonusAwardList, AllBonusAwardDetail, ProductBonusAwardList }
