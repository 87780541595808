import React from 'react'

const CreateNotification = React.lazy(() =>
  import('../pages/Notifications/CreateNotification/CreateNotification'),
)

const NotificationList = React.lazy(() =>
  import('../pages/Notifications/NotificationList/NotificationList'),
)

const NotificationDetail = React.lazy(() =>
  import('../pages/Notifications/NotificationDetail/NotificationDetail'),
)

export { NotificationList, CreateNotification, NotificationDetail }
