import React from 'react'
import PropTypes from 'prop-types'
import { CCol, CFormLabel } from '@coreui/react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const DateFilter = ({ payload, setPayload, startDate = null, endDate = null }) => {
  const handleStartDate = (date) => {
    setTimeout(
      setPayload((prevInfo) => {
        return {
          ...prevInfo,
          from_date: moment(date).format('YYYY-MM-DD'),
          start_date: moment(date).format('YYYY-MM-DD'),
        }
      }),
      500,
    )
  }

  const handleEndDate = (date) => {
    setTimeout(
      setPayload((prevInfo) => {
        return {
          ...prevInfo,
          to_date: moment(date).format('YYYY-MM-DD'),
          end_date: moment(date).format('YYYY-MM-DD'),
        }
      }),
      500,
    )
  }

  return (
    <>
      <CCol xs={12} md={4}>
        <CFormLabel htmlFor="start-date">Start Date</CFormLabel>
        <DatePicker
          id="start-date"
          dateFormat="dd/MM/yyyy"
          onChange={handleStartDate}
          placeholderText="Start Date..."
          value={
            startDate ? moment(startDate).format('DD/MM/YYYY') : new Date(payload['from_date'])
          }
          maxDate={endDate ? new Date(endDate) : new Date(payload['to_date'])}
          selected={
            startDate
              ? new Date(startDate)
              : payload['from_date']
              ? new Date(payload['from_date'])
              : ''
          }
          wrapperClassName="month_picker"
          className="form-control"
        />
      </CCol>
      <CCol xs={12} md={4} className="mb-3">
        <CFormLabel htmlFor="end-date">End Date</CFormLabel>
        <DatePicker
          id="end-date"
          dateFormat="dd/MM/yyyy"
          onChange={handleEndDate}
          placeholderText="End Date..."
          value={endDate ? moment(endDate).format('DD/MM/YYYY') : new Date(payload['to_date'])}
          minDate={startDate ? new Date(startDate) : new Date(payload['from_date'])}
          selected={
            endDate ? new Date(endDate) : payload['to_date'] ? new Date(payload['to_date']) : ''
          }
          wrapperClassName="month_picker"
          className="form-control"
        />
      </CCol>
    </>
  )
}

DateFilter.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default DateFilter
