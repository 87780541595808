import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './PreviewModal.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const PreviewModal = ({ children, show, onClose }) => {
  const modalRef = useRef(null)
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible)
    } else {
      modalRef.current.classList.remove(styles.visible)
    }
  }, [show])
  return (
    <>
      <div ref={modalRef} className={`${styles.modal}`}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{
            width: 20,
            height: 20,
            position: 'absolute',
            top: 65,
            right: 16,
            margin: '1rem',
            cursor: 'pointer',
            zIndex: '10',
            color: '#fff',
          }}
          className={styles.close__btn}
          onClick={onClose}
        />
        <div className={styles.modal__wrap}>{children}</div>
      </div>
    </>
  )
}

PreviewModal.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PreviewModal
