import React from 'react'
import PropTypes from 'prop-types'
import { CButton, CForm, CFormLabel, CFormSelect, CRow, CCol } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import DateRangePicker from 'react-bootstrap-daterangepicker'
// import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import DateFilter from 'src/components/Common/DateFilter'

const PostFilter = ({ payload, setPayload, handleSubmit }) => {
  const handleApply = (event, picker) => {
    setPayload({
      ...payload,
      from_date: picker.startDate.format('YYYY-MM-DD'),
      to_date: picker.endDate.format('YYYY-MM-DD'),
    })
    picker.element.val(
      picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'),
    )
  }
  const handleCancel = (event, picker) => {
    setPayload({
      ...payload,
      from_date: '',
      to_date: '',
    })
    picker.element.val('')
  }
  return (
    <>
      <CForm>
        <CRow>
          <DateFilter payload={payload} setPayload={setPayload} />
          {/* <CCol xs={12} md={4}>
            <div className="mb-3">
              <CFormLabel htmlFor="date">Date</CFormLabel>
              <DateRangePicker
                initialSettings={{
                  autoUpdateInput: false,
                  locale: {
                    cancelLabel: 'Clear',
                  },
                }}
                onApply={handleApply}
                onCancel={handleCancel}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="  📅 Start Date &#10230; 📅 End Date"
                  onChange={(e) => setPayload({ ...payload, from_date: '', to_date: '' })}
                />
              </DateRangePicker>
            </div>
          </CCol> */}

          <CCol xs={12} md={4}>
            <div className="mb-3">
              <CFormLabel htmlFor="status">Status</CFormLabel>
              <CFormSelect
                aria-label="All"
                onChange={(e) => setPayload({ ...payload, status: e.target.value })}
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="publish">Publish</option>
              </CFormSelect>
            </div>
          </CCol>
          {/* <CCol xs={12} md={4} className="btn_search__container mb-3">
            <CButton type="submit" className="btn_search">
              <FontAwesomeIcon icon={faSearch} color="#fff" className="btn_search__icon" />
              Filter
            </CButton>
          </CCol> */}
        </CRow>
      </CForm>
    </>
  )
}

PostFilter.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default PostFilter
