import React from 'react'

const YearEndTripList = React.lazy(() => import('../pages/YearEnd/Trips/List/YearEndTripList'))

const YearEndTripDetail = React.lazy(() =>
  import('../pages/YearEnd/Trips/Detail/YearEndTripDetail'),
)

const YearEndTripGroup = React.lazy(() => import('../pages/YearEnd/Trips/Groups/YearEndTripGroup'))

export { YearEndTripList, YearEndTripDetail, YearEndTripGroup }
