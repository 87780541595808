import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const UserPermission = ({ children, permission = 1 }) => {
  const auth = useSelector((state) => state.auth)
  return <>{auth.role >= permission && children}</>
}

UserPermission.propTypes = {
  children: PropTypes.any.isRequired,
  permission: PropTypes.number,
}

export default UserPermission
