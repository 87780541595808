import React from 'react'

const CurrencyRegionList = React.lazy(() =>
  import('../pages/CurrencyRegions/CurrencyRegionList/CurrencyRegionList'),
)
const CurrencyRegionDetail = React.lazy(() =>
  import('../pages/CurrencyRegions/CurrencyRegionDetail/CurrencyRegionDetail'),
)
const CreateCurrencyRegion = React.lazy(() =>
  import('../pages/CurrencyRegions/CreateCurrencyRegion/CreateCurrencyRegion'),
)
const EditCurrencyRegion = React.lazy(() =>
  import('../pages/CurrencyRegions/EditCurrencyRegion/EditCurrencyRegion'),
)

export { CurrencyRegionList, CreateCurrencyRegion, CurrencyRegionDetail, EditCurrencyRegion }
