import axios from 'axios'
import API from 'src/network/API'
import { API_HOST } from 'src/network/domain'
import { SET_BONUSTYPE } from './types'

const url = `${API_HOST}/api${API.typeList}`

export const fetchBonusTypes = (token) => async (dispatch) => {
  return axios
    .post(
      url,
      { page: 1, row_count: 30 },
      { headers: { Authorization: `Bearer ${token}`, 'device-type': 'web' } },
    )
    .then((response) => {
      const { data } = response
      dispatch({
        type: SET_BONUSTYPE,
        payload: data?.data,
      })
      return Promise.resolve(response)
    })
    .catch((error) => {
      const errors = error?.response?.data
      return Promise.reject(errors)
    })
}
