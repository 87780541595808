const generateBonus = {
  allBonusAward: '/admin/bonus-awards/all',
  allDetilBonusAward: '/admin/bonus-awards/all-details',
  tripAllDetail: '/admin/three-months-trips/all-details',
  allSixMonthsBonusAward: '/admin/highest-bonus-awards/all',
  sixMonthTripAll: '/admin/six-months-trips/all',
  yearEndAwardAllDetail: '/admin/year-end-awards/groups/all-details',
  yearEndTripAllDetail: '/admin/year-end-trips/groups/all-details',
}

export default generateBonus
