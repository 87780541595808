import React, { useEffect } from 'react'
import { CCol, CFormLabel, CFormSelect, CFormText } from '@coreui/react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProduct } from 'src/actions/product'
const ProductDropDown = ({
  payload,
  setPayload,
  disabled,
  payloadKey,
  title,
  errors,
  setErrors,
  cols = 12,
}) => {
  const dispatch = useDispatch()
  const products = useSelector((state) => state.products)
  const auth = useSelector((state) => state.auth.token)

  useEffect(() => {
    if (products.length === 0) {
      dispatch(fetchProduct(auth)).catch((error) => {
        const response = error.response?.data
      })
    }
  }, [products, auth])

  return (
    <CCol xs={12} md={cols}>
      <div className="mb-3">
        <CFormLabel htmlFor="product">{title ?? 'Product'}</CFormLabel>
        <CFormSelect
          disabled={disabled}
          aria-label="All"
          value={payload?.product_id ?? payload[payloadKey]}
          onChange={(e) => {
            setPayload({ ...payload, product_id: e.target.value, [payloadKey]: e.target.value })
            setErrors((error) => {
              return { ...error, exist: '' }
            })
          }}
          required
          onInvalid={(e) => e.target.setCustomValidity('Please select a product.')}
          onInput={(e) => e.target.setCustomValidity('')}
          defaultValue={payload.product_id ? payload.product_id : ''}
        >
          <option value="" disabled>
            Choose the product...
          </option>
          {products?.map((product, index) => (
            <option key={index} value={product.id}>
              {product.name}
            </option>
          ))}
        </CFormSelect>
        {errors?.product_id && (
          <CFormText id="product_id" className="text-danger">
            {errors?.product_id}
          </CFormText>
        )}
        {errors && errors[payloadKey] && (
          <CFormText id={payloadKey} className="text-danger">
            {errors[payloadKey]}
          </CFormText>
        )}
      </div>
    </CCol>
  )
}

ProductDropDown.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  payloadKey: PropTypes.string,
  title: PropTypes.string,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  cols: PropTypes.string,
}
export default ProductDropDown
