import { SET_BONUSGENERATE } from 'src/actions/types'

const initialState = {
  type: 'all',
  data: [],
  region: 'MM',
  bonusDate: '',
  frameFormat: 'png',
  frameQuality: 100,
  imageType: '',
  isGenerate: false,
  isTerminate: true,
  isRegenerate: false,
}
const bonusGenerateReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_BONUSGENERATE:
      return payload
    default:
      return state
  }
}

export default bonusGenerateReducer
