import React from 'react'

const MemberTypeList = React.lazy(() =>
  import('../pages/MemberTypes/MemberTypeList/MemberTypeList'),
)
const MemberTypeDetail = React.lazy(() =>
  import('../pages/MemberTypes/MemberTypeDetail/MemberTypeDetail'),
)
const CreateMemberType = React.lazy(() =>
  import('../pages/MemberTypes/CreateMemberType/CreateMemberType'),
)
const EditMemberType = React.lazy(() =>
  import('../pages/MemberTypes/EditMemberType/EditMemberType'),
)

export { MemberTypeList, MemberTypeDetail, CreateMemberType, EditMemberType }
