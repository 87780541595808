import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import API from 'src/network/API'
import useAxios from 'src/hooks/useAxios'

import toast from 'react-hot-toast'
import ProfileUpload from 'src/components/Uploads/ProfileUpload/ProfileUpload'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import AgentCode from '../../edit/AgentCode'

const BonusProfileUpload = () => {
  const { id } = useParams()
  const { instance } = useAxios()

  const [agent, setAgent] = useState(null)
  const [selectedImage, setSelectedImage] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchAgent = async () => {
    setLoading(true)
    const url = `${API.agentDetail}`
    const response = await instance
      .post(url, {
        agent_id: id,
      })
      .catch((error) => {
        const { code, message } = error.response.data
        if (code === 400) {
          setErrors((errors) => {
            return {
              ...errors,
              ...message,
            }
          })
          setLoading(false)
        }
      })
    if (response) {
      const data = response?.data.data
      setAgent(data)
      setSelectedImage(data.bonus_profile)
      setLoading(false)
    }
  }

  const uploadImage = async (image) => {
    setLoading(true)
    let fd = new FormData()
    fd.append('agent_id', agent?.agent_id)
    fd.append('image', image)

    const url = `${API.uploadBonusProfile}`

    const response = await instance.post(url, fd).catch((error) => {
      const errors = error.response.data
      const { code, message } = errors
      if (code === 400) {
        setErrors((errors) => {
          return {
            ...errors,
            ...message,
          }
        })
        setLoading(false)
      }
    })

    if (response) {
      const {
        data: { path },
      } = response.data

      setSelectedImage(path)
      toast.success('Profile Picture Uploaded Successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
      setLoading(false)
    }
  }

  const handleChangeImage = (image) => {
    uploadImage(image)
  }

  useEffect(() => {
    fetchAgent()
  }, [id])

  console.log('error', errors)

  return (
    <>
      <CRow className="d-flex justify-content-center">
        <CCol xs="12" md="6">
          <CCard className="shadow-lg">
            <CCardBody>
              <ProfileUpload
                selectedImage={selectedImage}
                handleChangeImage={handleChangeImage}
                showEdit={true}
                loading={loading}
              />
              <AgentCode
                agentId={agent ? `${agent?.name} - ${agent?.agent_id}` : ''}
                loading={loading}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  )
}

export default BonusProfileUpload
