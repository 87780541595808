import React from 'react'
import PropTypes from 'prop-types'
import { CTableRow, CTableDataCell } from '@coreui/react'
import FallbackImage from 'src/components/FallbackImage'
import AgentCard from 'src/components/AgentCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const ProductProfileTableRow = ({ index, productProfile, openModal, handleShowUpload }) => {
  return (
    <>
      <CTableRow>
        <CTableDataCell> {index + 1}</CTableDataCell>
        <CTableDataCell className="text-center px-2">
          <div className="agent_avatar">
            <FallbackImage
              path={productProfile.path}
              title={productProfile.name}
              openModal={openModal}
            />
          </div>
        </CTableDataCell>
        <CTableDataCell className="text-start">
          <AgentCard code={productProfile.agent_id} name={productProfile.agent_name} />
        </CTableDataCell>
        <CTableDataCell className="text-start">{productProfile.product_name}</CTableDataCell>
        <CTableDataCell className="text-center">
          {/* <Link to={`/product-profile/${productProfile.id}/upload`}> */}
          <span title="Upload Product Profile" className="mx-2">
            <FontAwesomeIcon
              icon={faEdit}
              style={{ cursor: 'pointer' }}
              color="rgb(33, 150, 243)"
              onClick={() =>
                handleShowUpload(
                  productProfile.path,
                  productProfile.product_id,
                  productProfile.product_name,
                )
              }
            />
          </span>
          {/* </Link> */}
        </CTableDataCell>
      </CTableRow>
    </>
  )
}

ProductProfileTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  productProfile: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  handleShowUpload: PropTypes.func.isRequired,
}

export default ProductProfileTableRow
