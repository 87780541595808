import React from 'react'

const PromotionList = React.lazy(() =>
  import('../pages/Promotions/Promotions/PromotionList/PromotionList'),
)
const PromotionDetail = React.lazy(() =>
  import('../pages/Promotions/Promotions/PromotionDetail/PromotionDetail'),
)
const CreatePromotion = React.lazy(() =>
  import('../pages/Promotions/Promotions/CreatePromotion/CreatePromotion'),
)
const EditPromotion = React.lazy(() =>
  import('../pages/Promotions/Promotions/EditPromotion/EditPromotion'),
)

const MonthlyPromotionList = React.lazy(() =>
  import('../pages/Promotions/MonthlyPromotion/MonthlyPromotionList/MonthlyPromotionList'),
)

const CreateMonthlyPromotion = React.lazy(() =>
  import('../pages/Promotions/MonthlyPromotion/CreateMonthlyPromotion/CreateMonthlyPromotion'),
)

const MonthlyPromotionDetail = React.lazy(() =>
  import('../pages/Promotions/MonthlyPromotion/MonthlyPromotionDetail/MonthlyPromotionDetail'),
)

const EditMonthlyPromotion = React.lazy(() =>
  import('../pages/Promotions/MonthlyPromotion/EditMonthlyPromotion/EditMonthlyPromotion'),
)

const FocPromotionList = React.lazy(() =>
  import('../pages/Promotions/FocPromotion/FocPromotionList/FocPromotionList'),
)

const CreateFocPromotion = React.lazy(() =>
  import('../pages/Promotions/FocPromotion/CreateFocPromotion/CreateFocPromotion'),
)

const FocPromotionDetail = React.lazy(() =>
  import('../pages/Promotions/FocPromotion/FocPromotionDetail/FocPromotionDetail'),
)

const EditFocPromotion = React.lazy(() =>
  import('../pages/Promotions/FocPromotion/EditFocPromotion/EditFocPromotion'),
)

const DiscountPromotionList = React.lazy(() =>
  import('../pages/Promotions/DiscountPromotion/DiscountPromotionList/DiscountPromotionList'),
)

const CreateDiscountPromotion = React.lazy(() =>
  import('../pages/Promotions/DiscountPromotion/CreateDiscountPromotion/CreateDiscountPromotion'),
)

const DiscountPromotionDetail = React.lazy(() =>
  import('../pages/Promotions/DiscountPromotion/DiscountPromotionDetail/DiscountPromotionDetail'),
)

const EditDiscountPromotion = React.lazy(() =>
  import('../pages/Promotions/DiscountPromotion/EditDiscountPromotion/EditDiscountPromotion'),
)

const DoublePromotionList = React.lazy(() =>
  import('../pages/Promotions/DoublePromotion/DoublePromotionList/DoublePromotionList.js'),
)

const CreateDoublePromotion = React.lazy(() =>
  import('../pages/Promotions/DoublePromotion/CreateDoublePromotion/CreateDoublePromotion'),
)

const EditDoublePromotion = React.lazy(() =>
  import('../pages/Promotions/DoublePromotion/EditDoublePromotion/EditDoublePromotion'),
)

const DoublePromotionDetail = React.lazy(() =>
  import('../pages/Promotions/DoublePromotion/DoublePromotionDetail/DoublePromotionDetail'),
)

export {
  PromotionList,
  PromotionDetail,
  CreatePromotion,
  EditPromotion,
  MonthlyPromotionList,
  CreateMonthlyPromotion,
  MonthlyPromotionDetail,
  EditMonthlyPromotion,
  FocPromotionList,
  CreateFocPromotion,
  FocPromotionDetail,
  EditFocPromotion,
  DiscountPromotionList,
  CreateDiscountPromotion,
  DiscountPromotionDetail,
  EditDiscountPromotion,
  DoublePromotionList,
  CreateDoublePromotion,
  EditDoublePromotion,
  DoublePromotionDetail,
}
