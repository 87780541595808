export const BonusTypeEnum = {
  Monthly: 1,
  ThreeMonth: 2,
  SixMonthCertificate: 3,
  SixMonthTrip: 4,
  YearEnd: 5,
}

export const YearEndImageTypeEnum = {
  DIGITAL: 'DIGITAL',
  PRINT: 'PRINT',
}

export const BonusGenerateTypeEnum = {
  MonthlyAll: 'all',
  MonthlyDetail: 'detail',
  ThreeMonthDetail: 'trip_product',
  SixMonthAll: 'six_months_bonus',
  SixMonthTrip: 'six_months_trip_all',
  YearEndAwardDetail: 'year_end_award_group',
  YearEndTripDetail: 'year_end_trip_group',
  AllBirthday: 'birthday',
}

export const FrameCategoryTypeEnum = {
  CERTIFICATE: 'certificate',
  TRIP: 'trip',
  NONE: 'none',
}

export const BonusTypeStatusEnum = {
  MONTHLY: 'monthly',
  THREEMTRIP: 'three_month_trip',
  SIXMTRIP: 'six_month_trip',
  HIGHEST: 'highest',
  ALL: 'all',
  PRODUCT: 'product',
  YETRIP: 'year_end_trip',
  YEAR: 'year',
}
