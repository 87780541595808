import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CCard, CCardBody } from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabPanel from '../../../../components/Material/TabPanel'
import ProductTable from '../../../../components/Agents/details/products/ProductTable'

import CommonBreadcrumb from '../../../../components/Common/CommonBreadcrumb'

const ProductList = () => {
  const { id } = useParams()
  let navigate = useNavigate()

  const [activeKey, setActiveKey] = useState(1)

  const handleChange = (event, newValue) => {
    setActiveKey(newValue)
    if (newValue === 0) {
      navigate(`/agents/${id}`)
    }
    if (newValue === 1) {
      navigate(`/agents/${id}/products`)
    }
    if (newValue === 2) {
      navigate(`/agents/${id}/regions`)
    }
    if (newValue === 3) {
      navigate(`/agents/${id}/posts`)
    }
    if (newValue === 4) {
      navigate(`/agents/${id}/bonus-profile`)
    }
    if (newValue === 5) {
      navigate(`/agents/${id}/product-profile`)
    }
  }
  return (
    <>
      <CommonBreadcrumb
        name="Agent Products"
        items={[{ name: 'Agents', route: '/agents' }, { name: 'Agent Products' }]}
      />
      <CCard>
        <CCardBody>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="product_table">
            <Tabs value={activeKey} onChange={handleChange} aria-label="Agent Product List">
              <Tab label="Detail" />
              <Tab label="Products" />
              <Tab label="Sale Region" />
              <Tab label="Posts" />
              <Tab label="Bonus Profile" />
              <Tab label="Product Profile" />
              {/* <Tab label="Gifts" /> */}
            </Tabs>
          </Box>
          <TabPanel value={activeKey} index={1}>
            <ProductTable />
          </TabPanel>
        </CCardBody>
      </CCard>
    </>
  )
}

export default ProductList
