import brands from './brands'
import advertisements from './advertisements'
import posts from './posts'
import tags from './tags'
import attributes from './attributes'
import promotions from './promotions'
import gifts from './gifts'
import products from './products'
import productAttributes from './product-attributes'
import productReviews from './product-reviews'
import productPosts from './product-posts'
import TopAgent from './top-agent'
import agents from './agents'
import registrations from './registrations'
import nationalIds from './national-id'
import transactions from './transactions'
import admin from './admin'
import dashboard from './dashboard'
import memberTypes from './member-types'
import inventories from './inventories'
import currencies from './currencies'
import currencyRegions from './currency-regions'
import exports from './exports'
import bonus from './bonus'
import bonusFrame from './bonus-frame'
import bonusGroups from './bonus-groups'
import purchases from './purchases'
import bonusAward from './bonus-award'
import regions from './regions'
import agentProducts from './agent-products'
import bonusProfile from './bonus-profile'
import productProfile from './product-profile'
import bonusType from './bonus-type'
import bonusRule from './bonus-rule'
import bonuslevel from './bonus-level'
import moneyImage from './money-image'
import agentReports from './agent-reports'
import users from './user'
import totalBonusAward from './total-bonus-award'
import productGroups from './trip-groups'
import trips from './trips'
import bonusPrize from './bonus-prize'
import sales from './sales'
import notifications from './notifications'
import surveys from './surveys'
import sixMonthsBonus from './six-months-bonus'
import sixMonthTrips from './six-months-trips'
import stockHistories from './stock-histories'
import yearEnd from './year-end'
import birthdayFrame from './birthday-frames'
import promotionGroup from './promotion-group'
import productFlyer from './product-flyers'
import invoiceTheme from './invoice-themes'
import agentLevels from './agent-level'
import generateBonus from './generateBonus'
import yearEndGroupAward from './year-end-group-awards'
import customer from './customer'
import generateBirthday from './generate-birthday'

const API = {
  ...brands,
  ...posts,
  ...tags,
  ...attributes,
  ...promotions,
  ...gifts,
  ...products,
  ...productAttributes,
  ...productReviews,
  ...productPosts,
  ...TopAgent,
  ...agents,
  ...agentProducts,
  ...advertisements,
  ...registrations,
  ...nationalIds,
  ...transactions,
  ...inventories,
  ...currencies,
  ...currencyRegions,
  ...bonus,
  ...dashboard,
  ...memberTypes,
  ...admin,
  ...exports,
  ...bonusFrame,
  ...bonusGroups,
  ...purchases,
  ...bonusAward,
  ...regions,
  ...bonusProfile,
  ...productProfile,
  ...bonusType,
  ...bonusRule,
  ...bonuslevel,
  ...moneyImage,
  ...agentReports,
  ...users,
  ...totalBonusAward,
  ...productGroups,
  ...trips,
  ...bonusPrize,
  ...sales,
  ...notifications,
  ...surveys,
  ...sixMonthsBonus,
  ...sixMonthTrips,
  ...stockHistories,
  ...yearEnd,
  ...birthdayFrame,
  ...promotionGroup,
  ...productFlyer,
  ...invoiceTheme,
  ...agentLevels,
  ...generateBonus,
  ...yearEndGroupAward,
  ...customer,
  ...generateBirthday,
}

export default API
