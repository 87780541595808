import React from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { CTableRow, CTableDataCell } from '@coreui/react'
import { strLimit } from '../../../../utils/index'

const PostTableRow = ({ paginateIndex, index, post }) => {
  const { id } = useParams()

  const statusBadge = (value) => {
    return value === 'PUBLISH' ? true : false
  }

  return (
    <CTableRow>
      <CTableDataCell className="text-center"> {paginateIndex + index}</CTableDataCell>
      <CTableDataCell>{strLimit(post.description, 40)}</CTableDataCell>
      <CTableDataCell className="text-center">
        <button
          className={`custom_btn ${
            statusBadge(post.status) ? 'custom_btn_success' : 'custom_btn_danger'
          }`}
        >
          {post.status}
        </button>
      </CTableDataCell>
      <CTableDataCell className="text-center">
        <Link to={`/agents/${id}/posts/${post.id}`}>
          <span title="Detail Agent" className="mx-2">
            <FontAwesomeIcon icon={faEye} color="rgb(33, 150, 243)" />
          </span>
        </Link>
      </CTableDataCell>
    </CTableRow>
  )
}

PostTableRow.propTypes = {
  paginateIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  post: PropTypes.object.isRequired,
}

export default PostTableRow
