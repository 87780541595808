import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CContainer, CRow, CCol } from '@coreui/react'
import noInternet from '../src/assets/animation/errors/No_internet.json'
import Lottie from 'lottie-react'

const OnlineStatus = (props) => {
  const { children } = props
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(true)
    }

    const handleOfflineStatus = () => {
      setIsOnline(false)
    }
    window.addEventListener('online', handleOnlineStatus)
    window.addEventListener('offline', handleOfflineStatus)
    return () => {
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOfflineStatus)
    }
  }, [])

  const handleRefresh = () => {
    window.location.reload()
  }

  return (
    <>
      {isOnline ? (
        children
      ) : (
        <>
          <div className="min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
              <CRow className="justify-content-center text-center">
                <CCol md={8}>
                  <div className="d-flex justify-content-center">
                    <Lottie
                      animationData={noInternet}
                      style={{ width: '150px' }}
                      alt="No Internet"
                    />
                  </div>
                  <h6 className="display-3 me-4">Whoops!</h6>
                  <p className="text-medium-emphasis">
                    No internet connection found. Check your internet or try again.
                  </p>
                  <button onClick={handleRefresh} className="btn bg-light">
                    Try Again
                  </button>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </>
      )}
    </>
  )
}

OnlineStatus.propTypes = {
  children: PropTypes.any,
}

export default OnlineStatus
