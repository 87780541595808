import React from 'react'

const BonusProfileList = React.lazy(() => import('../pages/BonusProfile/List/BonusProfileList'))

const BonusProfileUpload = React.lazy(() =>
  import('../pages/BonusProfile/Upload/BonusProfileUpload'),
)

const CreateBonusProfile = React.lazy(() =>
  import('../pages/BonusProfile/CreateBonusProfile/CreateBonusProfile'),
)

const ProductProfileList = React.lazy(() =>
  import('../pages/BonusProfile/ProductProfile/ProductProfileList'),
)

const CreateProductProfile = React.lazy(() =>
  import('../pages/BonusProfile/CreateProductProfile/CreateProductProfile'),
)

const ProductProfileUpload = React.lazy(() =>
  import('../pages/BonusProfile/Upload/ProductProfileUpload'),
)

export {
  BonusProfileList,
  BonusProfileUpload,
  CreateBonusProfile,
  ProductProfileList,
  CreateProductProfile,
  ProductProfileUpload,
}
