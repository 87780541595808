import React from 'react'

const InventoryList = React.lazy(() => import('../pages/Inventories/InventoryList/InventoryList'))
const InventoryHistoryList = React.lazy(() =>
  import('../pages/Inventories/InventoryHistoryList/InventoryHistoryList'),
)
const CreateInventory = React.lazy(() =>
  import('../pages/Inventories/CreateInventory/CreateInventory'),
)

export { InventoryList, InventoryHistoryList, CreateInventory }
