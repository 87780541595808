import React from 'react'

const SurveyList = React.lazy(() => import('../pages/Surveys/SurveyList/SurveyList'))

const SurveyResponse = React.lazy(() => import('../pages/Surveys/SurveyResponse/SurveyResponse'))

const CreateSurvey = React.lazy(() => import('../pages/Surveys/CreateSurvey/CreateSurvey'))

const SurveyQuestionResponse = React.lazy(() =>
  import('../pages/Surveys/SurveyResponse/SurveyQuestionResponse'),
)

const SurveyIndividualResponse = React.lazy(() =>
  import('../pages/Surveys/SurveyResponse/SurveyIndividualResponse'),
)
export {
  SurveyList,
  SurveyResponse,
  CreateSurvey,
  SurveyQuestionResponse,
  SurveyIndividualResponse,
}
