import { API_HOST } from '../network/domain'
import API from '../network/API'
import { SET_REGION } from './types'
import axios from 'axios'

const url = `${API_HOST}/api${API.allSaleRegion}`

export const fetchRegion = () => async (dispatch) => {
  const headers = {
    'device-Type': 'web',
  }

  return axios
    .post(url, {}, { headers })
    .then((response) => {
      const { data } = response
      dispatch({
        type: SET_REGION,
        payload: data?.data,
      })
      return Promise.resolve(response)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
