import { CButton, CCard, CCardBody } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import BonusFrameTabs from 'src/components/BonusFrames/BonusFrameTabs'
import CommonBreadcrumb from 'src/components/Common/CommonBreadcrumb'
import { BonusTypeEnum } from 'src/configs/EnumData'
import useAxios from 'src/hooks/useAxios'
import API from 'src/network/API'
import { paginateIteration } from 'src/utils'
import { Link } from 'react-router-dom'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TabPanel from 'src/components/Material/TabPanel'
import TotalCount from 'src/components/TotalCount/TotalCount'
import { ThreeMonthFrameTable } from 'src/components/BonusFrames/ThreeMonths/ThreeMonthFrameTable'
import ThreeMonthFrameFilter from 'src/components/BonusFrames/ThreeMonths/ThreeMonthFrameFilter'
import PreviewModal from 'src/components/Uploads/PreviewModal/PreviewModal'
import styles from 'src/components/Uploads/PreviewModal/PreviewModal'
import UserPermission from 'src/components/Common/UserPermission'

const ROW_COUNT = 5

export const ThreeMonthFrameList = () => {
  const { instance } = useAxios()

  const [paginateIndex, setPaginateIndex] = useState(0)
  const [pagination, setPagination] = useState({ currentPage: 1, totalCount: 0, totalPage: 0 })
  const [bonusFrames, setBonusFrames] = useState([])
  const [payload, setPayload] = useState({
    bonus_type_id: BonusTypeEnum.ThreeMonth,
    group_id: '',
    sale_region: '',
  })
  const [activeKey, setActiveKey] = useState(1)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const fetchBonusFrame = async (currentPage = 1) => {
    setLoading(true)
    const url = `${API.frameList}`

    const response = await instance
      .post(url, {
        page: currentPage ?? 1,
        row_count: ROW_COUNT,
        ...payload,
      })
      .catch((error) => {
        setLoading(false)
        const errors = error?.response?.data
        const { code, message } = errors
        if (code === 400) {
          Object.keys(message)?.map((key) =>
            toast.error(message[key], {
              position: 'top-right',
            }),
          )
        }
      })

    setLoading(false)
    if (response) {
      const { data } = response

      setPagination({
        ...pagination,
        currentPage: data?.pagination.page,
        totalCount: data?.pagination.total_records_count,
        totalPage: data?.pagination.total_page,
      })

      setBonusFrames([...data?.data])
      setPaginateIndex(paginateIteration(data?.data, data?.pagination.page, 5))
    }
  }

  const handlePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page,
    })
    fetchBonusFrame(page)
  }

  const handleBonusFrame = (frameId) => {
    const url = `${API.deleteFrame}`
    instance
      .post(url, {
        bonus_frame_id: frameId,
      })
      .then((response) => {
        toast.success('Bonus Frame Deleted Successfully!', {
          position: 'top-right',
        })
        setBonusFrames(bonusFrames.filter((frame) => frame.id !== frameId))
        setPagination({
          ...pagination,
          totalCount: pagination.totalCount - 1,
        })
      })
      .catch((error) => {
        const errors = error.response.data
        const { code, message } = errors

        if (code === 400) {
          Object.keys(message)?.map((key) =>
            toast.error(message[key], {
              position: 'top-right',
            }),
          )
        }
      })
  }

  useEffect(() => {
    fetchBonusFrame()
  }, [payload])

  const openModal = (path) => {
    setShow(true)
    setSelectedImage(path)
  }
  return (
    <div>
      <CommonBreadcrumb name="Three Month Frames" items={[{ name: 'Three Month Frames' }]} />
      <CCard>
        <CCardBody>
          <UserPermission>
            <div className="card_container">
              <Link to="/bonus-frames/product-frame/create?bonus_type=three-months">
                <CButton size="sm" className="card_container__button">
                  <FontAwesomeIcon icon={faPlus} color="#fff" /> Create Frame
                </CButton>
              </Link>
            </div>
          </UserPermission>
          <div className="mb-4">
            <BonusFrameTabs activeKey={activeKey} onTabChange={(value) => setActiveKey(value)} />
          </div>
          <TabPanel value={activeKey} index={1}>
            <ThreeMonthFrameFilter payload={payload} setPayload={setPayload} />
            {pagination?.totalCount > 0 && <TotalCount count={pagination?.totalCount} />}
            <ThreeMonthFrameTable
              paginateIndex={paginateIndex}
              totalCount={pagination?.totalCount}
              totalPage={pagination?.totalPage}
              currentPage={pagination?.currentPage}
              bonusFrames={bonusFrames}
              handlePage={handlePage}
              handleBonusFrame={handleBonusFrame}
              loading={loading}
              openModal={openModal}
            />
            {show && (
              <PreviewModal show={show} onClose={() => setShow(false)}>
                <div className="content">
                  {selectedImage && (
                    <>
                      <img
                        width={'500px'}
                        src={selectedImage}
                        alt="preview img"
                        className={styles.image_full_screen}
                      />
                    </>
                  )}
                </div>
              </PreviewModal>
            )}
          </TabPanel>
        </CCardBody>
      </CCard>
    </div>
  )
}
