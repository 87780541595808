const brands = {
  activeBrands: '/brands/list',
  inactiveBrands: '/brands/inactive-list',
  brandDetail: '/brands/detail',
  createBrand: '/brands/create',
  updateBrand: '/brands/update',
  updateBrandStatus: '/brands/update-status',
  deleteBrand: '/brands/delete',
  activeBrand: '/brands/active',
  allBrands: '/brands/all',
  uploadBrandImage: '/brands/upload-images',
  deleteBrandImage: '/brands/images/delete',
}

export default brands
