const bonusFrame = {
  frameList: '/bonus-frames/list',
  frameDetail: '/bonus-frames/detail',
  createFrame: '/bonus-frames/create',
  updateFrame: '/bonus-frames/update',
  deleteFrame: '/bonus-frames/delete',
  uploadFrameImage: '/bonus-frames/upload-image',
  deleteFrameImage: '/bonus-frames/delete-image',
  filterFrame: '/bonus-frames/filter',
}

export default bonusFrame
