const sixMonthsBonus = {
  sixMonthAwardList: '/admin/highest-bonus-awards/list',
  sixMonthAwardDetail: '/admin/highest-bonus-awards/detail',
  uploadSixMonthsBonus: '/admin/highest-bonus-awards/upload-image',
  downloadSixMonthsBonus: '/admin/highest-bonus-awards/download-image',
  downloadSixMonthsBonusExcel: '/admin/highest-bonus-awards/download-excel',
  downloadSixMonthsBonusDetailExcel: '/admin/highest-bonus-awards/details/download-excel',
}

export default sixMonthsBonus
