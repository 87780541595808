const agentReports = {
  monthlyAgentReport: '/admin/agents/monthly-reports/list',
  monthlyAgentReportDetail: '/admin/agents/monthly-reports/detail',
  monthlyAgentReportChildAgents: '/agent/childs/reports/list',
  monthlyAgentReportDirectChildAgents: '/agent/direct-childs/reports/list',
  monthlyAgentReportEndUserAgents: '/agent/end-user/reports/list',
  monthlyAgentReportEndUserDetail: '/agent/end-user/reports/detail',
  downloadMonthlyAgentReport: '/admin/agents/monthly-reports/overall/download-excel',
  downloadMonthlyAgentReportDetail: '/admin/agents/monthly-reports/details/download-excel',
  yearlyAgentReportDetail: '/admin/agents/yearly-reports/details/list',
  downloadYearlyAgentReport: '/admin/agents/yearly-reports/overall/download-excel',
  downloadYearlyAgentReportDetail: '/admin/agents/yearly-reports/details/download-excel',
}

export default agentReports
