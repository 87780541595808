import React from 'react'

//products
const ProductList = React.lazy(() => import('../pages/Products/ProductList/ProductList'))
const ProductDetail = React.lazy(() => import('../pages/Products/ProductDetail/ProductDetail'))
const CreateProduct = React.lazy(() => import('../pages/Products/CreateProduct/CreateProduct'))
const EditProduct = React.lazy(() => import('../pages/Products/EditProduct/EditProduct'))

//product attributes
const ProductAttributeList = React.lazy(() =>
  import('../pages/ProductAttributes/AttributeList/AttributeList'),
)
const ProductAttributeDetail = React.lazy(() =>
  import('../pages/ProductAttributes/AttributeDetail/AttributeDetail'),
)
const CreateProductAttribute = React.lazy(() =>
  import('../pages/ProductAttributes/CreateAttribute/CreateAttribute'),
)
const EditProductAttribute = React.lazy(() =>
  import('../pages/ProductAttributes/EditAttribute/EditAttribute'),
)

//product reviews
const ProductReviewList = React.lazy(() => import('../pages/ProductReviews/ReviewList/ReviewList'))
const ProductReviewDetail = React.lazy(() =>
  import('../pages/ProductReviews/ReviewDetail/ReviewDetail'),
)
const CreateProductReview = React.lazy(() =>
  import('../pages/ProductReviews/CreateReview/CreateReview'),
)
const EditProductReview = React.lazy(() => import('../pages/ProductReviews/EditReview/EditReview'))

//product posts
const ProductPostList = React.lazy(() => import('../pages/ProductPosts/PostList/PostList'))
const ProductPostDetail = React.lazy(() => import('../pages/ProductPosts/PostDetail/PostDetail'))
const CreateProductPost = React.lazy(() => import('../pages/ProductPosts/CreatePost/CreatePost'))
const EditProductPost = React.lazy(() => import('../pages/ProductPosts/EditPost/EditPost'))

//product sale promotin
const ProductSalePromotionList = React.lazy(() =>
  import('../pages/ProductPromotionEndUsers/SalePromotionList/SalePromotionList'),
)

const CreateProductSalePromotion = React.lazy(() =>
  import('../pages/ProductPromotionEndUsers/CreateSalePromotion/CreateSalePromotion'),
)
const EditProductSalePromotion = React.lazy(() =>
  import('../pages/ProductPromotionEndUsers/EditSalePromotion/EditSalePromotion'),
)

//product monthly promotion
const ProductMonthlyPromotion = React.lazy(() =>
  import('../pages/ProductMonthlyPromotion/MonthlyPromotionList/MonthlyPromotionList'),
)
const CreateProductMonthlyPromotion = React.lazy(() =>
  import('../pages/ProductMonthlyPromotion/CreateMonthlyPromotion/CreateProductMonthlyPromotion'),
)
const EditProductMonthlyPromotion = React.lazy(() =>
  import('../pages/ProductMonthlyPromotion/EditMonthlyPromotion/EditMonthlyPromotion'),
)

//product FOC promotion
const ProductFOCPromotion = React.lazy(() => import('../pages/ProductFOCs/FOCList/FOCList'))
const CreateProductFOC = React.lazy(() => import('../pages/ProductFOCs/CreateFOC/CreateProductFOC'))
const EditProductFOCPromotion = React.lazy(() => import('../pages/ProductFOCs/EditFOC/EditFOC'))

//product discount promotion
const ProductDiscountPromotion = React.lazy(() =>
  import('../pages/ProductDisounts/ProductDiscountList/DiscountList'),
)
const CreateProductDiscount = React.lazy(() =>
  import('../pages/ProductDisounts/CreateProductDiscount/CreateDiscount'),
)
const EditProductDiscount = React.lazy(() =>
  import('../pages/ProductDisounts/EditProductDiscount/EditProductDiscount'),
)

//product monthly bonus rule
const ProductMonthlyBonusRule = React.lazy(() =>
  import('../pages/ProductBonusRules//BonusRulesList/BonusRuleList'),
)
const CreateProductMonthlyBonusRule = React.lazy(() =>
  import('../pages/ProductBonusRules/CreateBonusRules/CreateProductBonusRules'),
)
const EditProductMonthlyBonusRule = React.lazy(() =>
  import('../pages/ProductBonusRules/EditBonusRules/EditProductBonusRule'),
)

const ProductFlyerList = React.lazy(() =>
  import('../pages/ProductFlyers/FlyerList/ProductFlyerList'),
)

const ProductFlyerDetail = React.lazy(() =>
  import('../pages/ProductFlyers/FlyerDetail/ProductFlyerDetail'),
)

const ProductCatalogueList = React.lazy(() =>
  import('../pages/ProductCatalogues/CatalogueList/ProductCatalogueList'),
)

const CreateProductCatalogue = React.lazy(() =>
  import('../pages/ProductCatalogues/CreateCatalogue/CreateProductCatalogue'),
)
const EditProductCatalogue = React.lazy(() =>
  import('../pages/ProductCatalogues/EditCatalogue/EditProductCatalogue'),
)

const ProductCatalogueDetail = React.lazy(() =>
  import('../pages/ProductCatalogues/CatalogueDetail/ProductCatalogueDetail'),
)
export {
  ProductList,
  ProductDetail,
  CreateProduct,
  EditProduct,
  ProductAttributeList,
  ProductAttributeDetail,
  CreateProductAttribute,
  EditProductAttribute,
  ProductReviewList,
  CreateProductReview,
  ProductReviewDetail,
  EditProductReview,
  ProductPostList,
  ProductPostDetail,
  CreateProductPost,
  EditProductPost,
  ProductSalePromotionList,
  CreateProductSalePromotion,
  EditProductSalePromotion,
  ProductMonthlyPromotion,
  CreateProductMonthlyPromotion,
  EditProductMonthlyPromotion,
  ProductFOCPromotion,
  CreateProductFOC,
  EditProductFOCPromotion,
  ProductDiscountPromotion,
  CreateProductDiscount,
  EditProductDiscount,
  ProductMonthlyBonusRule,
  CreateProductMonthlyBonusRule,
  EditProductMonthlyBonusRule,
  ProductFlyerList,
  ProductFlyerDetail,
  ProductCatalogueList,
  CreateProductCatalogue,
  EditProductCatalogue,
  ProductCatalogueDetail,
}
