const trips = {
  tripList: '/admin/three-months-trips/list',
  tripDetail: '/admin/three-months-trips/trip-detail',
  tripGroup: '/admin/three-months-trips/detail',
  downloadTrip: '/three-months-trips/download-excel',
  downloadTripDetail: '/three-months-trips/details/download-excel',
  tripProductUpload: '/admin/three-months-trips/groups/upload-image',
  tripProductDownload: '/admin/three-months-trips/groups/download-image',
  tripProductExport: '/three-months-trips/product-group/download-excel',
  tripReportList: '/three-months-trips/reports/list',
  tripReportDetail: '/three-months-trips/reports/list/detail',
  tripReportGroup: '/three-months-trips/reports/detail',
  tripReportGroupDetail: '/three-months-trips/reports/product-groups/detail',
  downloadTripReport: '/three-months-trips/reports/download-excel',
  downloadTripReportDetail: '/three-months-trips/reports/details/download-excel',
}

export default trips
