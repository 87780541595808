import axios from 'axios'
import { useSelector } from 'react-redux'
import { API_HOST } from '../network/domain'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LOGOUT } from '../actions/types'
import { setLoading, clearLoading } from '../actions/loading'

const useAxios = () => {
  const dispatch = useDispatch()

  let navigate = useNavigate()

  const instance = axios.create({
    baseURL: `${API_HOST}/api`,
  })
  const auth = useSelector((state) => state.auth)

  //request interceptor
  instance.interceptors.request.use((request) => {
    if (!request.url.includes('login')) {
      request.headers.common['Authorization'] = `Bearer ${auth.token}`
      request.headers.Accept = 'application/json'
      request.headers['device-type'] = 'mobile'

      if (
        request.url.includes('/admin/agents/create') ||
        request.url.includes('/agents/upload-profile') ||
        request.url.includes('/agents/products/list')
      ) {
        request.headers.post['Content-Type'] = 'multipart/form-data'
      } else {
        request.headers.post['Content-Type'] = 'application/json'
      }

      if (
        request.url.includes('/agents/list') ||
        request.url.includes('/agent/products/list') ||
        request.url.includes('/agents/sales/list') ||
        request.url.includes('/products/list') ||
        request.url.includes('/products/inactive-list') ||
        request.url.includes('/products/detail') ||
        request.url.includes('/products/attributes/detail') ||
        request.url.includes('/admin/products/attributes/upload-images') ||
        request.url.includes('/products/reviews/list') ||
        request.url.includes('/products/posts/list') ||
        request.url.includes('/products/attributes/list') ||
        request.url.includes('/admin/bonus-awards/list') ||
        request.url.includes('/three-months-total-bonus/list') ||
        request.url.includes('/admin/agents/birthdays/list')
      ) {
        request.headers.type = 'admin'
      }

      if (
        request.url.includes('/api/products/all') ||
        request.url.includes('/api/products/promotions/list') ||
        request.url.includes('/api/agents/download-excel') ||
        request.url.includes('/admin/agents/download-excel') ||
        request.url.includes('/product/reports/download') ||
        request.url.includes('/agent/reports/download') ||
        request.url.includes('/agent/monthly-reports/download')
      ) {
        //disable loading
        dispatch(clearLoading())
      } else {
        dispatch(setLoading())
      }

      return request
    }
  })

  //response interceptor
  instance.interceptors.response.use(
    (response) => {
      dispatch(clearLoading())
      return response
    },
    function (error) {
      dispatch(clearLoading())
      const response = error?.response
      console.log('response error::', response)
      if (!response) {
        navigate('/network-error')
        return false
      }

      if (response?.data?.code === 500) {
        navigate('/error')
        return false
      }

      if (response?.data?.code === 401 || response?.status === 401) {
        localStorage.removeItem('user')
        dispatch({
          type: LOGOUT,
        })
        navigate('/login')
        return false
      }

      if (response?.data?.code === 503) {
        navigate('/maintenance')
        return false
      }

      return Promise.reject(error)
    },
  )

  return { instance }
}

export default useAxios
