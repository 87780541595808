import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from '../actions/types'

const user = JSON.parse(localStorage.getItem('user'))

const initalUser = user?.token
  ? {
      name: user?.name,
      isLoggedIn: true,
      username: user?.username,
      role: user?.role,
      token: user?.token,
      expired_at: user?.expired_at,
    }
  : { name: null, isLoggedIn: false, username: null, role: null, token: null, expired_at: null }

const authReducer = (state = initalUser, action) => {
  const { type, payload } = action
  //console.log('payload', payload)

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        name: payload.name,
        isLoggedIn: true,
        username: payload.username,
        role: payload.role,
        token: payload?.token,
        expired_at: payload?.expired_at,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        name: null,
        isLoggedIn: false,
        username: null,
        role: null,
        token: null,
        expired_at: null,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        username: null,
        role: null,
        token: null,
        expired_at: null,
        name: null,
      }
    default:
      return state
  }
}

export default authReducer
