import { INFINITE_SCROLL_COUNT } from 'src/actions/types'

const initialState = 10
const infiniteScrollCountReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case INFINITE_SCROLL_COUNT: {
      return payload.count
    }
    default:
      return state
  }
}

export default infiniteScrollCountReducer
