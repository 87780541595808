import React from 'react'

const InvoiceThemeList = React.lazy(() =>
  import('../pages/InvoiceThemes/InvoiceThemeList/InvoiceThemeList'),
)

const InvoiceThemeDetail = React.lazy(() =>
  import('../pages/InvoiceThemes/InvoiceThemeDetail/InvoiceThemeDetail'),
)

const CreateInvoiceTheme = React.lazy(() =>
  import('../pages/InvoiceThemes/CreateInvoiceTheme/CreateInvoiceTheme'),
)

const EditInvoiceTheme = React.lazy(() =>
  import('../pages/InvoiceThemes/EditInvoiceTheme/EditInvoiceTheme'),
)

export { InvoiceThemeList, InvoiceThemeDetail, CreateInvoiceTheme, EditInvoiceTheme }
