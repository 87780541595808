const advertisements = {
  activeAdvertisements: '/advertisements/list',
  advertisementDetail: '/advertisements/detail',
  deleteAdvertisement: '/advertisements/delete',
  createAdvertisement: '/advertisements/create',
  updateAdvertisement: '/advertisements/update',
  uploadAdvertisementImage: '/advertisements/upload-images',
}

export default advertisements
