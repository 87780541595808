const bonusAward = {
  awardList: '/bonus-awards/list',
  allBonusDetail: '/admin/bonus-awards/award-detail',
  awardDetail: '/bonus-awards/detail',
  createAward: '/bonus-awards/create',
  updateAward: '/bonus-awards/update',
  deleteAward: '/bonus-awards/delete',
  importAward: '/bonus-awards/createBonusAwardExcel',
  awardProductList: '/bonus-awards/getagentbonusawarddetails',
  imageUpload: '/bonus-awards/upload-image',
  generateCount: '/admin/bonus-upload/completed-count',
}

export default bonusAward
