import React from 'react'

const BonusFrameList = React.lazy(() => import('../pages/BonusFrames/FrameList/FrameList'))
const BonusFrameDetail = React.lazy(() => import('../pages/BonusFrames/FrameDetail/FrameDetail'))
const CreateBonusFrame = React.lazy(() => import('../pages/BonusFrames/CreateFrame/CreateFrame'))
const CreateProductFrame = React.lazy(() =>
  import('../pages/BonusFrames/CreateFrame/CreateProductFrame'),
)
const EditBonusFrame = React.lazy(() => import('../pages/BonusFrames/EditFrame/EditFrame'))
const CreateYearEndFrame = React.lazy(() =>
  import('../pages/BonusFrames/CreateFrame/CreateYearEndFrame'),
)

const EditYearEndFrame = React.lazy(() => import('../pages/BonusFrames/EditFrame/EditYearEndFrame'))
export {
  BonusFrameList,
  CreateBonusFrame,
  CreateProductFrame,
  EditBonusFrame,
  BonusFrameDetail,
  CreateYearEndFrame,
  EditYearEndFrame,
}
