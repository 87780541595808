const { SET_AGENTLEVEL } = require('src/actions/types')

const initialState = null
const agentLevelReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_AGENTLEVEL:
      return payload
    default:
      return state
  }
}

export default agentLevelReducer
