import React from 'react'
import LoadingAnimation from '../../assets/animation/loading/Loading.json'
import TextLoading from '../../assets/animation/loading/text_loading.json'
import Lottie from 'lottie-react'

const Loading = () => {
  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center flex-column"
      style={{ height: '100vh' }}
    >
      <Lottie animationData={LoadingAnimation} style={{ width: '150px' }} alt="No Internet" />
      <span className="mt-3 d-flex align-items-center">
        Loading <Lottie animationData={TextLoading} style={{ width: '40px' }} />
      </span>
    </div>
  )
}

export default Loading
