import { SET_YEARENDTRIPGROUP } from 'src/actions/types'

const initialState = null

const yearEndTripGroupReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_YEARENDTRIPGROUP:
      return payload
    default:
      return state
  }
}

export default yearEndTripGroupReducer
