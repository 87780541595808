import { createRoot } from 'react-dom/client'
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducers from './reducers'

const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(thunk)))

const container = document.getElementById('root')

const root = createRoot(container)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
