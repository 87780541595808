const bonusGroups = {
  bonusGroupList: '/admin/bonus-groups/list',
  bonusGroupDetail: '/admin/bonus-groups/detail',
  createBonusGroup: '/admin/bonus-groups/create',
  updateBonusGroup: '/admin/bonus-groups/update',
  deleteBonusGroup: '/admin/bonus-groups/delete',
  allBonusGroup: '/admin/bonus-groups/all',
}

export default bonusGroups
