import React from 'react'

const GiftList = React.lazy(() => import('../pages/Gifts/GiftList/GiftList'))
const GiftDetail = React.lazy(() => import('../pages/Gifts/GiftDetail/GiftDetail'))
const CreateGift = React.lazy(() => import('../pages/Gifts/CreateGift/CreateGift'))
const EditGift = React.lazy(() => import('../pages/Gifts/EditGift/EditGift'))
const CreateEndUserGift = React.lazy(() => import('../pages/Gifts/CreateGift/CreateEndUserGift'))
const EndUserGiftList = React.lazy(() => import('../pages/Gifts/GiftList/EndUserGiftList'))
const EndUserGiftDetail = React.lazy(() => import('../pages/Gifts/GiftDetail/EndUserGiftDetail'))
const EditEndUserGift = React.lazy(() => import('../pages/Gifts/EditGift/EditEndUserGift'))

export {
  GiftList,
  GiftDetail,
  CreateGift,
  EditGift,
  CreateEndUserGift,
  EditEndUserGift,
  EndUserGiftDetail,
  EndUserGiftList,
}
