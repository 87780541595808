import React from 'react'
import PropTypes from 'prop-types'

const ProductInfo = ({ name, tooltipText }) => {
  return <span title={tooltipText}>{name}</span>
}

ProductInfo.propTypes = {
  name: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
}
export default ProductInfo
