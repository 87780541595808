const posts = {
  posts: '/posts/list',
  postDetail: '/posts/detail',
  createPost: '/agents/posts/create',
  updatePost: '/agents/posts/update',
  deletePost: '/agents/posts/delete',
  updatePostStatus: '/posts/update-status',
  agentPosts: '/agents/posts/list',
  productPosts: '/products/posts/list',
  createPostTag: '/agents/posts/tags/create',
  deletePostTag: '/agents/posts/tags/delete',
  uploadPostImage: '/agents/posts/upload-images',
  deletePostImage: '/posts/images/delete',
}

export default posts
