const memberTypes = {
  allMemberTypes: '/member-types/list',
  searchMemberType: '/member-types/search',
  memberTypeDetail: '/member-types/detail',
  createMemberType: '/member-types/create',
  updateMemberType: '/member-types/update',
  deleteMemberType: '/member-types/delete',
  activeMemeberList: '/member-types/active/list',
}

export default memberTypes
