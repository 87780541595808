import { TOGGLE_SIDEBAR, CURRENT_SIDEBAR } from '../actions/types'
const sidebarReducer = (state = true, action) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_SIDEBAR:
      return !state
    case CURRENT_SIDEBAR:
      return state
    default:
      return state
  }
}

export default sidebarReducer
