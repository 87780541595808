const currencies = {
  currency: '/currencies/list',
  createCurrency: '/currencies/create',
  updateCurrency: '/currencies/update',
  currencyDetail: '/currencies/detail',
  deleteCurrency: '/currencies/delete',
  currencyHistory: '/currency-histories/list',
  currencyHistoryDetail: '/currency-histories/detail',
  deleteCurrencyHistory: '/currency-histories/delete',
}

export default currencies
