const currencyRegions = {
  currencyRegion: '/sale-regions/list',
  createCurrencyRegion: '/sale-regions/create',
  updateCurrencyRegion: '/sale-regions/update',
  currencyRegionDetail: '/sale-regions/detail',
  deleteCurrencyRegion: '/sale-regions/delete',
  allSaleRegion: '/sale-regions/all',
  reportSaleRegion: '/sale-regions/report-regions',
}

export default currencyRegions
