const transactions = {
  saleList: '/agents/sales/list',
  purchaseList: '/agents/purchases/list',
  saleDetail: '/agents/vouchers/detail',
  deleteSale: '/agents/sales/delete',
  changeSale: '/agents/sales/change',
  updateSaleDate: '/agents/sales/update-date',
  productQtyUpdate: '/agents/sales/update',
  bonusAgentList: '/agents/agent_by_product/list',
  overAllTransitionList: '/agents/sales/overall/list',
  downloadTransaction: '/agents/sales/products/download-excel',
  luckyDrawExport: '/agents/end-users/lucky-draws/excel',
  newAgentVoucherList: '/prospective-agent-vouchers/list',
  newAgentVoucherExport: '/prospective-agent-vouchers/download-excel',
  endUserGiftVoucherList: '/end-user-gifts/vouchers/list',
  downloadEnduserGiftVoucher: '/end-user-gifts/vouchers/download',
}

export default transactions
