import React from 'react'

const AdvertisementList = React.lazy(() =>
  import('../pages/Advertisements/AdvertisementList/AdvertisementList'),
)
const AdvertisementDetail = React.lazy(() =>
  import('../pages/Advertisements/AdvertisementDetail/AdvertisementDetail'),
)
const CreateAdvertisement = React.lazy(() =>
  import('../pages/Advertisements/CreateAdvertisement/CreateAdvertisement'),
)
const EditAdvertisement = React.lazy(() =>
  import('../pages/Advertisements/EditAdvertisement/EditAdvertisement'),
)

export { AdvertisementList, AdvertisementDetail, CreateAdvertisement, EditAdvertisement }
