import React from 'react'

const SixMonthsBonusList = React.lazy(() =>
  import('../pages/SixMonthsBonusAwards/AllFrame/SixMonthsBonusList'),
)
const SixMonthsBonusDetail = React.lazy(() =>
  import('../pages/SixMonthsBonusAwards/SixMonthsBonusDetail/SixMonthsBonusDetail'),
)

export { SixMonthsBonusList, SixMonthsBonusDetail }
