import React from 'react'
import PropTypes from 'prop-types'
import { CTable, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'

const TableHeader = ({ list }) => {
  return (
    <CTableHead>
      <CTableRow>
        {list?.map((item, index) => (
          <CTableHeaderCell
            key={index}
            scope="col"
            className={item?.className ?? ''}
            style={item?.style ?? null}
            colSpan={item?.colSpan ?? null}
          >
            {item?.name ?? ''}
          </CTableHeaderCell>
        ))}
      </CTableRow>
    </CTableHead>
  )
}

TableHeader.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      className: PropTypes.string,
      style: PropTypes.object,
      colSpan: PropTypes.number,
    }),
  ),
}
export default TableHeader
