import React from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { CTableRow, CTableDataCell } from '@coreui/react'

const RegionTableRow = ({ index, region }) => {
  const { id } = useParams()

  const statusBadge = (value) => {
    return value === 1 ? true : false
  }

  return (
    <CTableRow>
      <CTableDataCell className="text-center"> {++index}</CTableDataCell>
      <CTableDataCell className="text-center">{region?.sale_region}</CTableDataCell>
      <CTableDataCell className="text-center">
        {parseInt(region?.is_main) === 1 ? 'Yes' : 'No'}
      </CTableDataCell>
      <CTableDataCell className="text-center">
        <button
          className={`custom_btn ${
            statusBadge(region.status) ? 'custom_btn_success' : 'custom_btn_danger'
          }`}
        >
          {parseInt(region.status) === 1 ? 'Active' : 'Inactive'}
        </button>
      </CTableDataCell>
      <CTableDataCell className="text-center">
        <Link to={`/agents/${id}/regions/${region.id}/edit`}>
          <span title="Detail Agent" className="mx-2">
            <FontAwesomeIcon icon={faEdit} color="rgb(33, 150, 243)" />
          </span>
        </Link>
      </CTableDataCell>
    </CTableRow>
  )
}

RegionTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  region: PropTypes.object.isRequired,
}

export default RegionTableRow
