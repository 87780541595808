import React from 'react'
const BirthdayAgentList = React.lazy(() =>
  import('../pages/BirthdayFrames/BirthdayAgent/BirthdayAgentList'),
)

const BirthdayFrameList = React.lazy(() =>
  import('../pages/BirthdayFrames/FrameList/BirthdayFrameList'),
)
const BirthdayFrameDetail = React.lazy(() =>
  import('../pages/BirthdayFrames/FrameDetail/BirthdayFrameDetail'),
)
const CreateBirthdayFrame = React.lazy(() =>
  import('../pages/BirthdayFrames/CreateFrame/CreateBirthdayFrame'),
)
const EditBirthdayFrame = React.lazy(() =>
  import('../pages/BirthdayFrames/EditFrame/EditBirthdayFrame'),
)

export {
  BirthdayFrameList,
  BirthdayFrameDetail,
  CreateBirthdayFrame,
  EditBirthdayFrame,
  BirthdayAgentList,
}
