const bonus = {
  bonusLevelList: '/bonus-levels/list',
  bonusLevelDetail: '/bonus-levels/detail',
  createBonusLevel: '/bonus-levels/create',
  updateBonusLevel: '/bonus-levels/update',
  deleteBonusLevel: '/bonus-levels/delete',
  bonusRuleList: '/bonus-rules/list',
  bonusRuleDetail: '/bonus-rules/detail',
  createBonusRule: '/bonus-rules/create',
  updateBonusRule: '/bonus-rules/update',
  deleteBonusRule: '/bonus-rules/delete',
  bonusAwardList: '/admin/bonus-awards/list',
  bonusAwardDetail: '/admin/bonus-awards/detail',
  uploadAllFrame: '/admin/bonus-awards/all/upload-image',
  uploadProductFrame: '/admin/bonus-awards/products/upload-image',
  downloadAllFrame: '/admin/bonus-awards/all/download-image',
  downloadProductFrame: '/admin/bonus-awards/products/download-image',
  exportBonusAward: '/agent/bonus-awards/download-excel',
  exportBonusDetailAward: '/agent/bonus-awards/detail/download-excel',
  exportProductBonusAward: '/agent/bonus-awards/products/download-excel',
}

export default bonus
