import React from 'react'

const BonusRuleListPage = React.lazy(() =>
  import('../pages/BonusRules/BonusRuleList/BonusRulePage'),
)
const CreateMonthlyBonusRule = React.lazy(() =>
  import('../pages/BonusRules/CreateBonusRule/CreateMonthlyBonusRule'),
)
const MonthlyBonusRuleDetail = React.lazy(() =>
  import('../pages/BonusRules/BonusRuleDetail/MonthlyBonusRuleDetail'),
)
const EditMonthlyBonusRule = React.lazy(() =>
  import('../pages/BonusRules/EditBonusRule/EditMonthlyBonusRule'),
)

const CreateThreeMonthsBonusRule = React.lazy(() =>
  import('../pages/BonusRules/CreateBonusRule/CreateThreeMonthsBonusRule'),
)

const ThreeMonthsBonusRuleDetail = React.lazy(() =>
  import('../pages/BonusRules/BonusRuleDetail/ThreeMonthsBonusRuleDetail'),
)

const EditThreeMonthsBonusRule = React.lazy(() =>
  import('../pages/BonusRules/EditBonusRule/EditThreeMonthsBonusRule'),
)

const CreateYearEndRule = React.lazy(() =>
  import('../pages/YearEndRules/CreateYearEndRule/CreateYearEndRule'),
)

const YearEndRuleDetail = React.lazy(() =>
  import('../pages/YearEndRules/YearEndRuleDetail/YearEndRuleDetail'),
)

const EditYearEndRule = React.lazy(() =>
  import('../pages/YearEndRules/EditYearEndRule/EditYearEndRule'),
)

export {
  BonusRuleListPage,
  CreateMonthlyBonusRule,
  MonthlyBonusRuleDetail,
  EditMonthlyBonusRule,
  CreateThreeMonthsBonusRule,
  EditThreeMonthsBonusRule,
  ThreeMonthsBonusRuleDetail,
  CreateYearEndRule,
  YearEndRuleDetail,
  EditYearEndRule,
}
