import React from 'react'

const YearEndBonusAwardList = React.lazy(() =>
  import('../pages/YearEnd/BonusAwards/List/YearEndBonusAwardList'),
)

const YearEndBonusAwardDetail = React.lazy(() =>
  import('../pages/YearEnd/BonusAwards/Detail/YearEndBonusAwardDetail'),
)

const YearEndBonusAwardChildList = React.lazy(() =>
  import('../pages/YearEnd/BonusAwards/Detail/YearEndBonusAwardChildAgentList'),
)

const YearEndBonusAwardProductGroup = React.lazy(() =>
  import('../pages/YearEnd/BonusAwards/Groups/YearEndBonusAwardGroup'),
)

export {
  YearEndBonusAwardList,
  YearEndBonusAwardDetail,
  YearEndBonusAwardChildList,
  YearEndBonusAwardProductGroup,
}
