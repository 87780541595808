import React, { useState } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import {
  CForm,
  CFormText,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
} from '@coreui/react'
import AgentCode from '../../edit/AgentCode'
import API from 'src/network/API'
import useAxios from 'src/hooks/useAxios'
import ProfileUpload from 'src/components/Uploads/ProfileUpload/ProfileUpload'
import ProductDropDown from 'src/pages/BonusProfile/CreateProductProfile/ProductDropDown'

const CreateProductProfile = ({ showCreate, agentId, setShowCreate, handleRefresh }) => {
  const [errors, setErrors] = useState('')
  const { instance } = useAxios()
  const [payload, setPayload] = useState({})
  const [createLoading, setCreateLoading] = useState(false)
  const [selectedCreateImage, setSelectedCreateImage] = useState('')

  const handleSubmit = async (e) => {
    setCreateLoading(true)
    e.preventDefault()

    const url = `${API.productProfileList}`

    const response = await instance
      .post(url, {
        agent_id: agentId,
        product_id: payload.product_id ? payload.product_id : '-',
        page: 1,
        row_count: 15,
      })
      .catch((error) => {
        setCreateLoading(false)
        const errors = error?.response?.data
        const { code, message } = errors

        if (code === 400) {
          setErrors(message)
        }
      })

    if (response) {
      const { data } = response
      if (data.data.length > 0) {
        if (!selectedCreateImage) {
          setErrors((error) => {
            return {
              ...error,
              exist: 'This Agent already has Profile for this Product',
              image: 'The image field is required',
            }
          })
        } else {
          setErrors((error) => {
            return { ...error, exist: 'This Agent already has Profile for this Product' }
          })
        }
        setCreateLoading(false)
      } else {
        createProductProfile()
      }
    }
  }

  const createProductProfile = async () => {
    setCreateLoading(true)
    let fd = new FormData()
    fd.append('agent_id', agentId)
    fd.append('product_id', payload.product_id)
    fd.append('image', selectedCreateImage)

    const url = `${API.uploadProductProfile}`

    const response = await instance.post(url, fd).catch((error) => {
      const errors = error.response.data
      const { code, message } = errors
      if (code === 400) {
        setCreateLoading(false)
        if (message.product_id) {
          const errorMessage = { ...message, product_id: 'Need to choose product' }
          setErrors(errorMessage)
        } else {
          setErrors(message)
        }
      }
    })

    if (response) {
      const {
        data: { path },
      } = response.data
      setCreateLoading(false)
      setSelectedCreateImage(path)
      toast.success('Profile Picture Created Successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
      handleRefresh()
      setShowCreate(false)
    }
  }

  const handleChangeImage = (image) => {
    setSelectedCreateImage(image)
    setErrors((error) => {
      return { ...error, image: '' }
    })
  }

  const handleCancel = () => {
    setSelectedCreateImage('')
    setShowCreate(false)
    setPayload({})
    setErrors('')
  }

  return (
    <>
      <CModal
        visible={showCreate}
        onClose={handleCancel}
        aria-labelledby="importModal"
        aria-describedby="modalDescription"
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle>Create Product Profile</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm onSubmit={(e) => handleSubmit(e)}>
            <ProfileUpload
              selectedImage={selectedCreateImage}
              handleChangeImage={handleChangeImage}
              showEdit={true}
            />
            {errors.image && (
              <CFormText id="name" className="text-danger text-center mb-3">
                {errors.image}!
              </CFormText>
            )}
            <AgentCode agentId={agentId} />
            <ProductDropDown payload={payload} setPayload={setPayload} setErrors={setErrors} />
            {errors.product_id && (
              <CFormText id="name" className="text-danger mb-3">
                {errors.product_id}!
              </CFormText>
            )}
            {errors.exist && (
              <CFormText id="name" className="text-danger mb-3">
                {errors.exist}!
              </CFormText>
            )}
            <CButton type="submit" className="btn_success">
              {createLoading && <span className="spinner-border spinner-grow-sm me-1"></span>}
              Save
            </CButton>
            <CButton className="btn_back" onClick={handleCancel}>
              Cancel
            </CButton>
          </CForm>
        </CModalBody>
      </CModal>
    </>
  )
}

CreateProductProfile.propTypes = {
  showCreate: PropTypes.bool.isRequired,
  agentId: PropTypes.string.isRequired,
  setShowCreate: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
}

export default CreateProductProfile
