import { CFormLabel, CFormSelect } from '@coreui/react'
import React from 'react'
import PropTypes from 'prop-types'
import { ImageTypeList } from 'src/configs/YearEndImageTypeList'

const YearEndImageTypeDropDown = ({ payload, setPayload, isAllOption = false }) => {
  const handleChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setPayload((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }
  return (
    <div className="mb-3">
      <CFormLabel htmlFor="image_type">
        Image Type <span className="text-danger">*</span>
      </CFormLabel>
      <CFormSelect
        id="image_type"
        name="image_type"
        value={payload?.image_type}
        onChange={handleChange}
      >
        {isAllOption && <option value="">All</option>}
        {payload?.image_type === null && <option value="">-- Choose Image Type --</option>}
        {ImageTypeList?.map((info, index) => (
          <option key={index} value={info?.id}>
            {info?.name}
          </option>
        ))}
      </CFormSelect>
    </div>
  )
}

YearEndImageTypeDropDown.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  isAllOption: PropTypes.bool,
}
export default YearEndImageTypeDropDown
