import React from 'react'
import { CCard, CCardBody, CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const CommonBreadcrumb = ({ name, items }) => {
  return (
    <CCard className="card_breadcrumb">
      <CCardBody className="card_breadcrumb__body">
        <CBreadcrumb style={{ '--cui-breadcrumb-divider': "'>'" }}>
          <h5 className="card_breadcrumb__title">{name}</h5>
          <CBreadcrumbItem>
            <Link to={`/dashboard`}>
              <FontAwesomeIcon icon={faHome} color="rgb(33, 150, 243)" />
            </Link>
          </CBreadcrumbItem>
          {items?.map((info, index) =>
            !info?.route ? (
              <CBreadcrumbItem key={index} className="active">
                {info?.name}
              </CBreadcrumbItem>
            ) : (
              <CBreadcrumbItem key={index} className="active">
                <Link to={`${info?.route}`}>{info?.name}</Link>
              </CBreadcrumbItem>
            ),
          )}
        </CBreadcrumb>
      </CCardBody>
    </CCard>
  )
}

CommonBreadcrumb.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}
export default CommonBreadcrumb
