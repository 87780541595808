import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { isObject } from '../../../utils'
import PreviewModal from '../PreviewModal/PreviewModal'
import './ProfileUpload.scss'
import styles from '../PreviewModal/PreviewModal.module.css'

const ProfileUpload = ({
  selectedImage,
  handleChangeImage,
  maxSize = 3,
  showEdit = true,
  loading = false,
}) => {
  const [show, setShow] = useState(false)

  const image =
    selectedImage && (isObject(selectedImage) ? URL.createObjectURL(selectedImage) : selectedImage)

  const uploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0]

      const megaBytes = maxSize * (1024 * 1024)

      if (image.size > megaBytes) {
        alert(`Please upload a file smaller than ${maxSize} MB`)
        return false
      }

      handleChangeImage(image)
    }
  }
  const handleMouseOver = () => {
    setShow(true)
  }

  return (
    <>
      <div className="avatar-upload">
        <div className="avatar-edit">
          <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" onChange={uploadImage} />

          {showEdit &&
            (loading ? (
              <span className="spinner-border spinner-grow-sm me-1"></span>
            ) : (
              <label htmlFor="imageUpload">
                <FontAwesomeIcon icon={faPencilAlt} className="fa-pencil" />
              </label>
            ))}
        </div>
        <div className="avatar-preview" onMouseOver={handleMouseOver}>
          {selectedImage && (
            <>
              <img src={image} alt="Upload img" />
            </>
          )}
        </div>
        {showEdit && (
          <div className="accept-image">
            Allowed <span className="text-danger">*.jpeg, *.jpg, *.png </span> <br />
            Max size of <span className="text-danger">{maxSize} MB</span>
          </div>
        )}
        {/* <PreviewModal show={show} onClose={() => setShow(false)}>
          <div className="content">
            {selectedImage && (
              <>
                <img
                  width="100%"
                  src={image}
                  alt="Upload Image"
                  className={styles.image_full_screen}
                />
              </>
            )}
          </div>
        </PreviewModal> */}
      </div>
    </>
  )
}

ProfileUpload.propTypes = {
  selectedImage: PropTypes.any,
  handleChangeImage: PropTypes.func,
  maxSize: PropTypes.number,
  showEdit: PropTypes.bool,
  loading: PropTypes.bool,
}

export default ProfileUpload
