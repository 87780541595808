import axios from 'axios'
import API from 'src/network/API'
import { API_HOST } from 'src/network/domain'
import { SET_PRODUCTGROUPS } from './types'

const url = `${API_HOST}/api${API.allBonusGroup}`

export const fetchProductGroups = (token, payload) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCTGROUPS,
    payload: [],
  })
  return axios
    .post(
      url,
      { page: 1, row_count: 40, ...payload },
      { headers: { Authorization: `Bearer ${token}`, 'device-Type': 'web' } },
    )
    .then((response) => {
      const { data } = response
      dispatch({
        type: SET_PRODUCTGROUPS,
        payload: data?.data ?? [],
      })
      return Promise.resolve(response)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
