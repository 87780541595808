import React from 'react'

const NationalIdList = React.lazy(() =>
  import('../pages/NationalIds/NationalIdList/NationalIdList'),
)
const NationalIdDetail = React.lazy(() =>
  import('../pages/NationalIds/NationalIdDetail/NationalIdDetail'),
)
const CreateNationalId = React.lazy(() =>
  import('../pages/NationalIds/CreateNationalId/CreateNationalId'),
)
const EditNationalId = React.lazy(() =>
  import('../pages/NationalIds/EditNationalId/EditNationalId'),
)

export { NationalIdList, NationalIdDetail, CreateNationalId, EditNationalId }
