const agentLevels = {
  agentLevelList: '/agent-level-types/list',
  createAgentLevel: '/agent-level-types/create',
  agentLevelUpdate: '/agent-level-types/update',
  agentLevelDetail: '/agent-level-types/detail',
  agentLevelDeletImage: '/agent-level-types/delete-image',
  deleteAgentLevel: '/agent-level-types/delete',
  agentWithLevelList: '/admin/agents/agent-levels/list',
  deleteAgentWithLevel: '/admin/agents/agent-levels/delete',
  importAgentLevel: '/admin/agents/agent-levels/import',
  downloadAgentLevel: '/admin/agents/agent-levels/download-excel',
}

export default agentLevels
