import React from 'react'
import PropTypes from 'prop-types'
import styles from './TotalCount.module.css'

const TotalCount = ({ count }) => {
  const formatCount = count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return (
    <div className={styles.count_container}>
      Total Count - <span className={styles.count_badge}>{formatCount}</span>
    </div>
  )
}

TotalCount.propTypes = {
  count: PropTypes.any.isRequired,
}

export default TotalCount
