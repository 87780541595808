import React, { useState, useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
} from '@coreui/react'
import { useParams } from 'react-router-dom'
import API from '../../../../network/API'
import useAxios from '../../../../hooks/useAxios'
import { paginateIteration, omit, emptyObject } from '../../../../utils/index'
import PostFilter from './PostFilter'
import PostTableRow from './PostTableRow'
import ResultNotFound from '../../../ResultNotFound'
import ErrorMessage from '../../../ErrorMessage'
import Pagination from '../../../Pagination/Pagination'
import TableLoading from '../../../TableLoading'

const PostTable = () => {
  const { instance } = useAxios()
  const { id } = useParams()

  const [posts, setPosts] = useState([])
  const [payload, setPayload] = useState({
    agent_id: id,
    keyword: '',
    status: '',
    from_date: '',
    to_date: '',
  })
  const [paginateIndex, setPaginateIndex] = useState(0)
  const [pagination, setPagination] = useState({ currentPage: 1, totalCount: 0, totalPage: 0 })
  const [error, setError] = useState('')
  const [notFound, setNotFound] = useState(true)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchPosts()
  }, [payload, pagination.currentPage])

  const fetchPosts = async (page = 1) => {
    if (!emptyObject(payload) || page) {
      setLoading(true)
    }

    const url = `${API.dashboardAgentPost}`
    const response = await instance
      .post(url, {
        ...payload,
        agent_id: id,
        page: pagination.currentPage,
        row_count: 15,
      })
      .catch((error) => {
        const errors = error?.response.data
        const { code, message } = errors
        if (code === 400) {
          setError(message)
        }
      })

    if (response) {
      const { data } = response
      setLoading(false)

      setPagination({
        ...pagination,
        currentPage: data?.pagination.page,
        totalCount: data?.pagination.total_records_count,
        totalPage: data?.pagination.total_page,
      })

      setPosts([...data?.data])

      setPaginateIndex(paginateIteration(data?.data, data?.pagination.page, 15))
    }
  }

  const handlePage = (page) => {
    setPagination({
      ...pagination,
      currentPage: page,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setPagination({
      ...pagination,
      currentPage: 1,
    })
    setNotFound(emptyObject(omit('agent_id', payload)))

    if (pagination.currentPage === 1) {
      fetchPosts()
    }
  }

  return (
    <>
      <PostFilter payload={payload} setPayload={setPayload} handleSubmit={handleSubmit} />
      <CCard>
        <CCardBody>
          {!error ? (
            <>
              <CTable hover responsive>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="text-center">
                      #
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-center">
                      Status
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-center">
                      View
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {!loading &&
                    (posts.length > 0 ? (
                      posts.map((post, index) => (
                        <PostTableRow
                          paginateIndex={paginateIndex}
                          index={index}
                          key={post.id}
                          post={post}
                        />
                      ))
                    ) : (
                      <ResultNotFound colspan={5} text="Posts" show={!notFound} />
                    ))}
                </CTableBody>
                {loading && <TableLoading colspan={12} />}
              </CTable>
              {pagination.totalCount > 0 && (
                <nav className="col-12 d-flex justify-content-end mt-4">
                  <Pagination
                    totalCount={pagination.totalCount}
                    pageSize={pagination.totalPage}
                    currentPage={pagination.currentPage}
                    onPageChange={(page) => handlePage(page)}
                  />
                </nav>
              )}
            </>
          ) : (
            <ErrorMessage error={error} />
          )}
        </CCardBody>
      </CCard>
    </>
  )
}

export default PostTable
