import React from 'react'

const CurrencyList = React.lazy(() => import('../pages/Currencies/CurrencyList/CurrencyList'))
const CurrencyDetail = React.lazy(() => import('../pages/Currencies/CurrencyDetail/CurrencyDetail'))
const CreateCurrency = React.lazy(() => import('../pages/Currencies/CreateCurrency/CreateCurrency'))
const EditCurrency = React.lazy(() => import('../pages/Currencies/EditCurrency/EditCurrency'))
const CurrencyHistoryList = React.lazy(() =>
  import('../pages/Currencies/CurrencyHistoryList/CurrencyHistoryList'),
)

export { CurrencyList, CreateCurrency, CurrencyDetail, EditCurrency, CurrencyHistoryList }
