import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from './routes/router'
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/style.scss'
import Loading from './components/Loading/Loading'
import OnlineStatus from './OnlineStatus'

const App = () => {
  return (
    <OnlineStatus>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Router />
        </Suspense>
      </BrowserRouter>
    </OnlineStatus>
  )
}

export default App
