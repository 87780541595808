import React from 'react'

const YearEndAwardList = React.lazy(() => import('../pages/YearEnd/Awards/List/YearEndAwardList'))

const YearEndAwardDetail = React.lazy(() =>
  import('../pages/YearEnd/Awards/Detail/YearEndAwardDetail'),
)

const YearEndAwardProductGroup = React.lazy(() =>
  import('../pages/YearEnd/Awards/Groups/YearEndAwardGroup'),
)

export { YearEndAwardList, YearEndAwardDetail, YearEndAwardProductGroup }
