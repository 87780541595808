import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { CFormLabel, CFormSelect, CCol, CFormText } from '@coreui/react'
import { fetchRegion } from '../../actions/region'

const RegionDropDown = ({
  show = true,
  payload,
  setPayload,
  disabled,
  cols = 4,
  errors,
  payloadKey,
}) => {
  const dispatch = useDispatch()
  const regions = useSelector((state) => state.regions)

  useEffect(() => {
    if (regions.length === 0) {
      const fetchRegions = async () => {
        dispatch(fetchRegion()).catch((error) => {
          const response = error.response?.data
        })
      }
      fetchRegions()
    }
  }, [regions])

  return (
    <CCol xs={12} md={cols}>
      <div className="mb-3">
        <CFormLabel htmlFor="sale_region">Region</CFormLabel>
        <CFormSelect
          disabled={disabled}
          aria-label="All"
          value={payload?.sale_region ?? ''}
          onChange={(e) => setPayload({ ...payload, sale_region: e.target.value })}
        >
          {show && <option value="">All</option>}
          {regions?.map((region) => (
            <option key={region.id} value={region.short_code}>
              {region.name}
            </option>
          ))}
        </CFormSelect>
      </div>
      {errors?.sale_region && (
        <CFormText id="sale_region" className="text-danger">
          {errors?.sale_region}
        </CFormText>
      )}
      {errors && errors[payloadKey] && (
        <CFormText id={payloadKey} className="text-danger">
          {errors[payloadKey]}
        </CFormText>
      )}
    </CCol>
  )
}

RegionDropDown.propTypes = {
  show: PropTypes.bool,
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  cols: PropTypes.string,
  errors: PropTypes.object,
  payloadKey: PropTypes.string,
}

export default React.memo(RegionDropDown)
