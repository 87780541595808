import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import API from 'src/network/API'
import useAxios from 'src/hooks/useAxios'
import { useSelector } from 'react-redux'
import ProductProfileTableRow from './ProductProfileTableRow'
import ProductProfileUpload from './ProductProfileUpload'
import ResultNotFound from 'src/components/ResultNotFound'
import TableLoading from 'src/components/TableLoading'
import PreviewModal from 'src/components/Uploads/PreviewModal/PreviewModal'
import styles from '../../../Uploads/PreviewModal/PreviewModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  CCard,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CRow,
  CButton,
} from '@coreui/react'
import CreateProductProfile from './CreateProductProfile'

const ProductProfileTable = () => {
  const { id } = useParams()
  const { instance } = useAxios()
  const [agentId, setAgentId] = useState('')
  const [errors, setErrors] = useState({})
  const [showPreview, setShowPreview] = useState(false)
  const [showUpload, setShowUpload] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [productId, setProductId] = useState(0)
  const [productName, setProductName] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  const [productProfile, setProductProfile] = useState([])
  const [loading, setLoading] = useState(true)
  const [createRefresh, setCreateRefresh] = useState(false)

  const fetchAgentForProductProfile = async () => {
    const url = `${API.agentDetail}`
    const response = await instance
      .post(url, {
        agent_id: id,
      })
      .catch((error) => {
        const { code, message } = error?.response.data
        if (code === 400) {
          setErrors((errors) => {
            return { ...errors, ...message }
          })
        }
      })
    if (response) {
      const {
        data: { data },
      } = response
      setAgentId(data?.agent_id)
      fetchProductProfile(data?.agent_id)
    }
  }

  const fetchProductProfile = async (agent_id) => {
    setLoading(true)
    const url = `${API.productProfileList}`
    const response = await instance
      .post(url, {
        agent_id: agent_id,
        page: 1,
        row_count: 100,
      })
      .catch((error) => {
        const { code, message } = error?.response?.data

        if (code === 400) {
          setErrors((errors) => {
            return { ...errors, ...message }
          })
          setLoading(false)
        }
      })
    if (response) {
      const {
        data: { data },
      } = response

      setProductProfile([...data])
      setLoading(false)
    }
  }

  const openModal = (path) => {
    setShowPreview(true)
    setSelectedImage(path)
  }

  const handleShowUpload = (image, productId, productName) => {
    setShowUpload(true)
    setSelectedImage(image)
    setProductId(productId)
    setProductName(productName)
  }

  const handleShowCreate = () => {
    setSelectedImage('')
    setShowCreate(true)
  }

  const handleRefresh = () => {
    if (createRefresh) {
      setCreateRefresh(false)
    } else {
      setCreateRefresh(true)
    }
  }

  useEffect(() => {
    fetchAgentForProductProfile()
  }, [id, createRefresh])
  return (
    <>
      <div className="card_container">
        <CButton
          size="sm"
          className="card_container__button mb-3"
          onClick={() => handleShowCreate()}
        >
          <FontAwesomeIcon icon={faPlus} color="rgb(255, 255, 255)" /> New Product Profile
        </CButton>
      </div>
      <CCard>
        <CCardBody>
          <CTable hover responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">#</CTableHeaderCell>
                <CTableHeaderCell scope="col">Profile</CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-start">
                  Agent
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-start">
                  Product
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-center">
                  Actions
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {!loading &&
                (productProfile.length > 0 ? (
                  productProfile.map((productProfile, index) => (
                    <ProductProfileTableRow
                      index={index}
                      key={productProfile.id}
                      productProfile={productProfile}
                      openModal={openModal}
                      handleShowUpload={handleShowUpload}
                    />
                  ))
                ) : (
                  <ResultNotFound colspan={13} text="Product Profiles" show={true} />
                ))}
            </CTableBody>
            {loading && <TableLoading colspan={13} />}
          </CTable>
          {showPreview && (
            <PreviewModal show={showPreview} onClose={() => setShowPreview(false)}>
              <div className="content">
                {selectedImage && (
                  <>
                    <img
                      width={'500px'}
                      src={selectedImage}
                      alt="preview img"
                      className={styles.image_full_screen}
                    />
                  </>
                )}
              </div>
            </PreviewModal>
          )}
          <ProductProfileUpload
            agentId={agentId}
            productId={productId}
            productName={productName}
            showUpload={showUpload}
            setShowUpload={setShowUpload}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            handleRefresh={handleRefresh}
            className={styles.image_full_screen}
          />
          <CreateProductProfile
            showCreate={showCreate}
            agentId={agentId}
            // selectedImage={selectedImage}
            handleRefresh={handleRefresh}
            setShowCreate={setShowCreate}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default ProductProfileTable
