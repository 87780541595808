import React from 'react'

const RegistrationList = React.lazy(() =>
  import('../pages/Registrations/RegistrationList/RegistrationList'),
)
const CreateRegistration = React.lazy(() =>
  import('../pages/Registrations/CreateRegistration/CreateRegistration.js'),
)

export { RegistrationList, CreateRegistration }
