import { SET_PRODUCT } from 'src/actions/types'

const initialState = []
const productReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_PRODUCT:
      return payload
    default:
      return state
  }
}

export default productReducer
