import React from 'react'

const YearEndReportList = React.lazy(() =>
  import('../pages/YearEnd/Reports/List/YearEndReportList'),
)

const YearEndReportDetail = React.lazy(() =>
  import('../pages/YearEnd/Reports/Detail/YearEndReportDetail'),
)

const YearEndReportGroup = React.lazy(() =>
  import('../pages/YearEnd/Reports/Products/YearEndReportGroup.js'),
)

export { YearEndReportList, YearEndReportDetail, YearEndReportGroup }
