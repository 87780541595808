import React, { useState, useEffect } from 'react'
import {
  CCard,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CButton,
} from '@coreui/react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import API from '../../../../network/API'
import useAxios from '../../../../hooks/useAxios'
import { emptyObject } from '../../../../utils/index'
import RegionTableRow from './RegionTableRow'
import ResultNotFound from '../../../ResultNotFound'
import ErrorMessage from '../../../ErrorMessage'
import TableLoading from '../../../TableLoading'

const RegionTable = () => {
  const { instance } = useAxios()
  const { id } = useParams()

  const [regions, setRegions] = useState([])
  const [payload, setPayload] = useState({
    agent_id: id,
  })
  const [error, setError] = useState('')
  const [notFound, setNotFound] = useState(true)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchPosts()
  }, [])

  const fetchPosts = async (page = 1) => {
    if (!emptyObject(payload) || page) {
      setLoading(true)
    }

    const url = `${API.saleRegions}`
    const response = await instance
      .post(url, {
        ...payload,
        agent_id: id,
      })
      .catch((error) => {
        const errors = error?.response.data
        const { code, message } = errors
        if (code === 400) {
          setError(message)
        }
      })

    if (response) {
      const { data } = response
      setLoading(false)

      setRegions([...data?.data])
    }
  }

  return (
    <>
      <div className="card_container mb-3">
        <Link to={`/agents/${id}/regions/create`}>
          <CButton size="sm" className="card_container__button">
            <FontAwesomeIcon icon={faPlus} color="rgb(255, 255, 255)" /> New Region
          </CButton>
        </Link>
      </div>
      <CCard>
        <CCardBody>
          {!error ? (
            <>
              <CTable hover responsive>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="text-center">
                      #
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-center">
                      Sale Region
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-center">
                      Main
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-center">
                      Status
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="text-center">
                      Edit
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {!loading &&
                    (regions.length > 0 ? (
                      regions.map((region, index) => (
                        <RegionTableRow index={index} key={region.id} region={region} />
                      ))
                    ) : (
                      <ResultNotFound colspan={5} text="Region" show={!notFound} />
                    ))}
                </CTableBody>
                {loading && <TableLoading colspan={12} />}
              </CTable>
            </>
          ) : (
            <ErrorMessage error={error} />
          )}
        </CCardBody>
      </CCard>
    </>
  )
}

export default RegionTable
