import React from 'react'
import PropTypes from 'prop-types'

const FallbackImage = ({ path, title, openModal, clickable = true }) => {
  return (
    <>
      {path ? (
        <img
          src={path}
          alt={title}
          loading="lazy"
          onClick={() => {
            if (clickable) {
              openModal(path)
            }
          }}
        />
      ) : (
        <img
          src="/images/fallback.png"
          loading="lazy"
          height="257px"
          alt={title}
          onClick={() => {
            if (clickable) {
              openModal('/images/fallback.png')
            }
          }}
        />
      )}
    </>
  )
}

FallbackImage.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  openModal: PropTypes.func,
  clickable: PropTypes.bool,
}

export default FallbackImage
