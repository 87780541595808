import React from 'react'

const GroupList = React.lazy(() =>
  import('../pages/ProductGroups/ProductGroupList/ProductGroupList'),
)

const GroupDetail = React.lazy(() =>
  import('../pages/ProductGroups/ProductGroupDetail/ProductGroupDetail'),
)

const CreateGroup = React.lazy(() =>
  import('../pages/ProductGroups/CreateProductGroup/CreateProductGroup'),
)

const EditGroup = React.lazy(() =>
  import('../pages/ProductGroups/EditProductGroup/EditProductGroup'),
)

// three months trips bonus rules
const TriBonusRulesList = React.lazy(() =>
  import('../pages/ProductGroups/TriBonusRules/TriBonusRulesList/TriBonusRulesList'),
)

const CreateTriBonusRules = React.lazy(() =>
  import('../pages/ProductGroups/TriBonusRules/CreateTriBonusRules/CreateTriBonusRules'),
)
const EditTriBonusRules = React.lazy(() =>
  import('../pages/ProductGroups/TriBonusRules/EditTriBonusRules/EditTriBonusRules'),
)

export {
  GroupList,
  GroupDetail,
  CreateGroup,
  EditGroup,
  TriBonusRulesList,
  CreateTriBonusRules,
  EditTriBonusRules,
}
