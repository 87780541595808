import React from 'react'

const TripList = React.lazy(() => import('../pages/Trips/TripList/TripList'))
const TripDetail = React.lazy(() => import('../pages/Trips/TripListDetail/TripListDetail'))
const TripProductGroup = React.lazy(() => import('../pages/Trips/ProductGroups/ProductGroup'))
const TripReportList = React.lazy(() => import('../pages/Trips/TripReportList/TripReportList'))
const TripReportListDetail = React.lazy(() =>
  import('../pages/Trips/TripReportListDetail/TripReportListDetail'),
)
const TripReportProductGroup = React.lazy(() =>
  import('../pages/Trips/ReportProductGroups/ReportProductGroup'),
)
const TripReportProductGroupDetail = React.lazy(() =>
  import('../pages/Trips/ReportProductGroupsDetail/ReportProductGroupsDetail'),
)

const SixMonthTripList = React.lazy(() => import('../pages/Trips/TripList/SixMonthsTripList'))
const SixMonthTripDetail = React.lazy(() =>
  import('../pages/Trips/ProductGroups/SixMonthsProductGroup'),
)
export {
  TripList,
  TripDetail,
  TripReportListDetail,
  TripProductGroup,
  TripReportList,
  TripReportProductGroup,
  TripReportProductGroupDetail,
  SixMonthTripList,
  SixMonthTripDetail,
}
