import { SET_LOADING, CLEAR_LOADING } from '../actions/types'

const loadingReducer = (state = true, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING:
      return true
    case CLEAR_LOADING:
      return false
    default:
      return state
  }
}

export default loadingReducer
