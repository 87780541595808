const surveys = {
  surveyList: '/admin/survey-forms/list',
  createSurvey: '/admin/survey-forms/create',
  surveyDetail: '/admin/survey-forms/responses/list',
  surveyIndividual: '/admin/survey-forms/responses/agents/list',
  deleteSurvey: '/admin/survey-forms/delete',
  questionTypeList: '/survey-forms/question-types/list',
  tripAgentBonusList: '/agents/bonus-agents/list',
  downloadReponseExcel: '/survey-forms/responses/download-excel',
}

export default surveys
