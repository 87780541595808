const six_month_trips = {
  sixMonthTripList: '/admin/six-months-trips/list',
  sixMonthTripDetail: '/admin/six-months-trips/detail',
  uploadSixMonthTrip: '/admin/six-months-trips/all/upload-image',
  downloadSixMonthTripImage: '/admin/six-months-trips/all/download-image',
  downloadSixMonthTrip: '/six-months-trips/download-excel',
  downloadSixMonthTripDetail: '/six-months-trips/details/download-excel',
  downloadSixMonthTripProduct: '/six-months-trips/product-group/download-excel',
}

export default six_month_trips
