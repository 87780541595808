import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

const Authorization = ({ children, permission = 1 }) => {
  const auth = useSelector((state) => state.auth)
  return auth?.role >= permission ? children : <Navigate to="/unauthorized" />
}

Authorization.propTypes = {
  children: PropTypes.any.isRequired,
  permission: PropTypes.number,
}

export default Authorization
