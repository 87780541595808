const productGroups = {
  groupList: '/admin/groups/list',
  groupDetail: '/admin/groups/detail',
  createGroup: '/admin/groups/create',
  updateGroup: '/admin/groups/update',
  deleteGroup: '/admin/groups/delete',
  deleteProductGroup: '/admin/product-groups/delete',
  bonusTypeList: '/bonus-types/list',
}

export default productGroups
