import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../pages/Agents/EditAgent/EditAgent.module.css'

const AgentCode = ({ agentId }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <button className={styles.agent_code__btn}>{agentId}</button>
      </div>
    </>
  )
}

AgentCode.propTypes = {
  agentId: PropTypes.string.isRequired,
}

export default AgentCode
