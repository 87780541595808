const tags = {
  activeTags: '/tags/list',
  inactiveTags: '/tags/inactive-list',
  tagDetail: '/tags/detail',
  createTag: '/tags/create',
  updateTag: '/tags/update',
  deleteTag: '/tags/delete',
  activeTag: '/tags/active',
  searchTag: '/tags/search',
}

export default tags
