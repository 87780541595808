import React from 'react'
import { CCard, CCardBody, CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Breadcrumb = () => {
  return (
    <>
      <CCard className="card_breadcrumb">
        <CCardBody className="card_breadcrumb__body">
          <CBreadcrumb style={{ '--cui-breadcrumb-divider': "'>'" }}>
            <h5 className="card_breadcrumb__title">Agent Gifts</h5>
            <CBreadcrumbItem>
              <Link to={`/dashboard`}>
                <FontAwesomeIcon icon={faHome} color="rgb(33, 150, 243)" />
              </Link>
            </CBreadcrumbItem>
            <CBreadcrumbItem className="active">Agent Gifts</CBreadcrumbItem>
          </CBreadcrumb>
        </CCardBody>
      </CCard>
    </>
  )
}

export default React.memo(Breadcrumb)
