import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CCol, CForm, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import ProductGroupDropDown from 'src/components/Common/ProductGroupDropDown'
import RegionDropDown from 'src/components/SaleRegions/RegionDropDown'
import YearEndImageTypeDropDown from 'src/components/Common/YearEndImageTypeDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBonusTypes } from 'src/actions/bonusType'
import { BonusTypeEnum } from 'src/configs/EnumData'
import { frameCategoryList } from 'src/configs/YearEndImageTypeList'

export const YearEndFrameFilter = ({ payload, setPayload }) => {
  const handleChange = (event) => {
    const { name, value } = event.target
    setPayload((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  return (
    <CForm>
      <CRow>
        <CCol xs={12} md={4}>
          <CFormLabel htmlFor="category">Category</CFormLabel>
          <CFormSelect
            id="category"
            name="category"
            value={payload?.category}
            onChange={handleChange}
          >
            <option value={''}>All</option>
            {frameCategoryList?.map((info, index) => (
              <option key={info?.id} value={info?.value}>
                {info?.name}
              </option>
            ))}
          </CFormSelect>
        </CCol>
        <CCol xs={12} md={4}>
          <YearEndImageTypeDropDown isAllOption payload={payload} setPayload={setPayload} />
        </CCol>
        <RegionDropDown payload={payload} setPayload={setPayload} />
        <CCol xs={12} md={4}>
          <ProductGroupDropDown
            payload={payload}
            setPayload={setPayload}
            regionFilter={true}
            payloadKey="product_id"
          />
        </CCol>
      </CRow>
    </CForm>
  )
}

YearEndFrameFilter.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
}
