export const ImageTypeList = [
  {
    id: 0,
    name: 'DIGITAL',
  },
  {
    id: 1,
    name: 'PRINT',
  },
]

export const frameCategoryList = [
  {
    id: 0,
    name: 'Certificate',
    value: 'certificate',
  },
  {
    id: 1,
    name: 'Trip',
    value: 'trip',
  },
  {
    id: 3,
    name: 'None',
    value: 'none',
  },
]
