import React from 'react'
import PropTypes from 'prop-types'
import { CTableRow, CTableDataCell } from '@coreui/react'
import { FcSearch } from 'react-icons/fc'

const ResultNotFound = ({ colspan, text, show }) => {
  return (
    <>
      {show && (
        <CTableRow>
          <CTableDataCell colSpan={colspan}>
            <div className="d-flex justify-content-center py-3">
              <span>
                <FcSearch size={20} />
                {text} Not Found!
              </span>
            </div>
          </CTableDataCell>
        </CTableRow>
      )}
    </>
  )
}

ResultNotFound.propTypes = {
  colspan: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool,
}

export default ResultNotFound
