const bonuslevel = {
  levelList: '/bonus-levels/list',
  levelDetail: '/bonus-levels/detail',
  createLevel: '/bonus-levels/create',
  updateLevel: '/bonus-levels/update',
  deleteLevel: '/bonus-levels/delete',
  allLevelList: '/bonus-levels/all',
}

export default bonuslevel
