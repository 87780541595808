import { SET_YEARENDAWARDGROUP } from 'src/actions/types'

const initialState = null

const yearEndAwardGroupReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_YEARENDAWARDGROUP:
      return payload
    default:
      return state
  }
}

export default yearEndAwardGroupReducer
