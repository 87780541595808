import React from 'react'

const YearEndGroupAwardList = React.lazy(() =>
  import('../pages/YearEndGroupAwards/GroupAwardList/GroupAwardList'),
)

const YearEndGroupAwardDetail = React.lazy(() =>
  import('../pages/YearEndGroupAwards/GroupAwardDetail/GroupAwardDetail'),
)

const CreateYearEndGroupAward = React.lazy(() =>
  import('../pages/YearEndGroupAwards/CreateGroupAward/CreateGroupAward'),
)

const EditYearEndGroupAward = React.lazy(() =>
  import('../pages/YearEndGroupAwards/EditGroupAward/EditGroupAward'),
)

export {
  YearEndGroupAwardList,
  YearEndGroupAwardDetail,
  CreateYearEndGroupAward,
  EditYearEndGroupAward,
}
