import React from 'react'
import PropTypes from 'prop-types'
import FallbackImage from './FallbackImage'

const AgentCard = ({ code, name, path = null, imageStatus = false, openModal = null }) => {
  return (
    <div className="agent_card">
      {imageStatus && (
        <div className="agent_avatar">
          <FallbackImage path={path} title={name} openModal={openModal ?? (() => {})} />
        </div>
      )}
      {/* <div className="agent_avatar">
        <FallbackImage path={path} title={name} />
      </div> */}
      <div className="agent_container" style={{ width: imageStatus ? 'calc(100%-100px)' : '100%' }}>
        <div className="agent_name" title={name}>
          {name}
        </div>
        <div className="agent_code">{code}</div>
      </div>
    </div>
  )
}

AgentCard.propTypes = {
  code: PropTypes.string,
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  imageStatus: PropTypes.bool,
  openModal: PropTypes.func,
}
export default AgentCard
