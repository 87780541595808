const productAttributes = {
  allAttributes: '/admin/attributes/list',
  productAttributeList: '/products/attributes/list',
  productAttributeDetail: '/products/attributes/detail',
  createProductAttribute: '/admin/products/attributes/create',
  editProductAttribute: '/products/attributes/update',
  deleteProductAttribute: '/products/attributes/delete',
  uploadProductAttributeImages: '/admin/products/attributes/upload-images',
  deleteProductAttributeImage: '/products/attributes/images/delete',
  productAttributeSortOrder: 'products/attributes/sort-order',
}

export default productAttributes
