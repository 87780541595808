import { API_HOST } from 'src/network/domain'
import API from 'src/network/API'
import { SET_PRODUCT } from './types'
import axios from 'axios'

const url = `${API_HOST}/api${API.allProducts}`

export const fetchProduct = (token) => async (dispatch) => {
  return axios
    .post(url, {}, { headers: { Authorization: `Bearer ${token}`, 'device-Type': 'web' } })
    .then((response) => {
      const { data } = response
      dispatch({
        type: SET_PRODUCT,
        payload: data?.data,
      })
      return Promise.resolve(response)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
